import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const BuyNowButton = ({text}) => {
  const [price, setPrice] = useState(null);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    document.addEventListener("voucher_price_changed", (e) => {
      setPrice(e.detail.price);
    })
  }, []);

  useEffect(() => {
    if (price === '0.00') {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }, [price]);


  return (
    <>
      {showButton &&
        <div class="Button--buy-button">
          <button className={"Button Button--full-width"}>{text}</button>
          <input type={"hidden"} name={"payment_method"} value={"voucher"}/>
        </div>
      }
    </>
  )
}

export default BuyNowButton

BuyNowButton.propTypes = {
  text: PropTypes.string.isRequired,
}
