import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import debounce from 'lodash/debounce';


import { loadUpdatedContent } from '../shared/components/load_updated_content';
import { downloadFile } from '../shared/components/download_file';

import SubmitPopups from '../shared/components/submit_popups';

import ConfirmationPopup from '../shared/components/confirmation_popup';

export default class ElsterDownloadButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      disabled: false,
      popupVersion: 1,
      confirmationTriggered: false
    }

    this.closePopup = this.closePopup.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitToElster = this.submitToElster.bind(this);
  }

  handleSubmit() {
    if (this.props.withConfirmation && !this.props.used) {
      this.setState({ confirmationTriggered: true, disabled: true });
    } else {
      this.submitToElster();
    }
  }

  submitToElster() {
    const _this = this;
    const data = new FormData();
    const config = {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': _this.props.csrfToken
      },
      responseType: 'arraybuffer'
    }

    // It turns on waiting behaviours, downloads the pdf when returned from Elster and updates the page
    _this.setState({ showPopup: true, confirmationTriggered: false, disabled: true }, () => {
      axios.put(_this.props.controllerUrl, data, config)
        .then((response) => {
          if (response.headers['content-type'].includes('application/pdf')) {
            downloadFile(response);

            this.props.draft && this.trackDraftDownload();
            this.props.withConfirmation && this.trackSubmissionOffline();

            // Reload the content of the page
            loadUpdatedContent(this.props.anchor);
            _this.setState({ showPopup: false, disabled: false })
          } else {
            _this.setState({ popupVersion: 2 })
          }
        }).catch((error) => {
          _this.setState({ popupVersion: 2 })
        });
    });
  }

  trackDraftDownload() {
    const eventName = 'submission_draft_download';
    const eventProperties = {
      'category': 'submission',
      'user_agent': this.props.userAgent,
      'ip': this.props.ipAddress
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({ event: eventName, eventProperties });
    }
  }

  trackSubmissionOffline() {
    const eventName = 'submission_offline_download';
    const eventProperties = {
      'category': 'submission',
      'user_agent': this.props.userAgent,
      'ip': this.props.ipAddress
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({ event: eventName, eventProperties });
    }
  }

  closePopup() {
    this.setState({
      showPopup: false,
      disabled: false,
      popupVersion: 1,
      confirmationTriggered: false
    });
  }

  buttonCssClass() {
    const optional = this.props.used ? 'Button--secondary' : '';
    return `Button Button--primary Button--md ${optional}`;
  }

  render() {
    return (
      <div>
        <button
          onClick={debounce(this.handleSubmit, 250)}
          className={this.buttonCssClass()}
          disabled={this.state.disabled}>
          {this.props.buttonMessage}
        </button>
        {this.state.confirmationTriggered ?
          <div>
            <ConfirmationPopup
              onSubmit={this.submitToElster}
              messages={this.props.popupMessages.confirmation}
              closePopup={this.closePopup}
            />
          </div>
          : null
        }
        <SubmitPopups
          showPopups={this.state.showPopup}
          popupVersion={this.state.popupVersion}
          popupMessages={this.props.popupMessages}
          closePopup={this.closePopup}
        />
      </div>
    )
  }
}

ElsterDownloadButton.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  controllerUrl: PropTypes.string.isRequired,
  buttonMessage: PropTypes.string.isRequired,
  popupMessages: PropTypes.object.isRequired,
  anchor: PropTypes.string,
  used: PropTypes.bool,
  withConfirmation: PropTypes.bool,
  outline: PropTypes.bool,
}
