import React from 'react';
import PropTypes from 'prop-types';
import TaxRefund from './tax_refund.jsx';

const DeclarationCardText = ({ locales, declaration }) => (
  <div className="DeclarationsPerson__cardContent">
    <h3 className="Text--big Text--tax-year Heading">
      {declaration.tax_year || locales.no_year}
    </h3>
    <h5 className="Text Text--app-audience Text--wunder-black ">
      {declaration.app_audience}
    </h5>
    <TaxRefund valueString={`${declaration.estimated_return}`} status={declaration.status}/>
  </div>
);

DeclarationCardText.propTypes = {
  locales: PropTypes.object,
  declaration: PropTypes.object
};

export default DeclarationCardText;
