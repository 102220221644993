import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import urlFor from '../../utils/url_helpers';
import { downloadFile } from '../../shared/components/download_file';

export default class VerificationStateBox extends Component {
  constructor(props) {
    super(props);

    this.descriptionSwitch = this.descriptionSwitch.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.state = {
      linkRef: this.props.declarationsIndexUrl
    };
  }

  componentDidMount() {
    this.setState({
      formAction: urlFor(this.state.linkRef)
    });
  }

  handleDownload() {
    const data = new FormData();
    const config = {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': this.props.csrfToken
      }
    }

    axios.put(this.props.downloadDeclarationUrl, data, config)
      .then((response) => {
        downloadFile(response);
      }).catch((error) => {
        console.log(error);
      });
  }

  descriptionSwitch() {
    const { messages, idState, submittedAt, done, type } = this.props;
    const message = (type === 'property_tax' ?
      messages.approved.description_property_tax : '')
    .replace('%{submitted_at}', submittedAt)

    if (idState === 'approved' || done) {
      return (
        <div className="OnlineSubmission__approved-description">
          <div className="Text Text--copy Text--wunder-grey Text--with-margin" dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      )
    } else {
      return (
        <>
          <div className="Text Text--copy Text--wunder-grey Text--with-margin">
            {this.props.type === 'property_tax' ? messages.pending.pt_description_1 : ''}
          </div>
          <div className="Text Text--copy Text--wunder-grey Text--with-margin">
            {messages.pending.description_2}
          </div>
        </>
      );
    }
  }

  render() {
    const { messages, idState, done } = this.props;
    const reopenDeclaration =
      <a onClick = { (e) => e.preventDefault() }
         className="Button Button--primary Button--full-width Button-reopen-declaration js-popup-open js-tracking-click"
         data-popup-id="reopen">
         {messages.approved.reopen}
      </a>
    return (
      <></>
    );
  }
}

VerificationStateBox.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  declarationsIndexUrl: PropTypes.string.isRequired,
  downloadDeclarationUrl: PropTypes.string.isRequired,
  idState: PropTypes.string.isRequired,
  submittedAt: PropTypes.string,
  done: PropTypes.bool.isRequired,
  declarationPath: PropTypes.string.isRequired
}
