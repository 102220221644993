import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ElsterDownloadButton from '../../components/elster_download_button';

export default class PrintedSubmissionBox extends Component {
  constructor(props) {
    super(props);

    this.descriptionSwitch = this.descriptionSwitch.bind(this);
  }

  descriptionSwitch() {
    const { done, taxOfficeAddress, messages } = this.props;

    if (done) {
      return (
        <div className="Box Box--no-margin-bottom Box--aligned-horizontally">
          <div
            className="Text Text--copy Text--white Text--with-margin"
            dangerouslySetInnerHTML={ {__html: messages.printed.description} }
          ></div>
          <pre className="Text Text--copy Text--white">{taxOfficeAddress}</pre>
        </div>
      )
    } else {
      return (
        <div>
          <div className="Text Text--copy Text--blue" dangerouslySetInnerHTML={ {__html: messages.printed.description} }></div>
          <div className="Box Box--no-margin Box--aligned-horizontally">
            <div className="Text Text--copy Text--blue">
              <pre className="Text Text--pre-wrap">{taxOfficeAddress}</pre>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { done, active, messages, csrfToken, submitDeclarationUrl, summaryPathUrl, daysSincePurchase, userAgent, ipAddress } = this.props;

    return (
      <div>
        <div>
          {
            this.descriptionSwitch()
          }
        </div>

        <div>
          <ElsterDownloadButton
            controllerUrl={submitDeclarationUrl}
            csrfToken={csrfToken}
            buttonMessage={messages.printed.cta}
            popupMessages={messages.elster_download}
            used={done}
            anchor="step3"
            withConfirmation={true}
            daysSincePurchase={daysSincePurchase}
            userAgent={userAgent}
            ipAddress={ipAddress}
            outline={true}
          />
        </div>
      </div>
    );
  }
}

PrintedSubmissionBox.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  submitDeclarationUrl: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  done: PropTypes.bool.isRequired,
  taxOfficeAddress: PropTypes.string
}
