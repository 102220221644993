import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Delay from 'react-delay';

import ClosePopup from '../../shared/components/close_popup';

export default class WaitingPopup extends Component {
  render() {
    // Average response time from Elster service.
    // See - https://trello.com/c/1naNcGM8/3907-error-message-for-elsters-downtime
    const waitingTime = 2000;
    const { message, closePopup } = this.props;

    return (
      <Delay wait={waitingTime}>
        <div className="Popup Popup--overlay Popup--visible">
          <div className="Popup__content Popup__content--wide">
            <ClosePopup closePopup={closePopup} />
            <div className="ElsterWaitingPopup__waiting-image"></div>
            <div className="ElsterWaitingPopup__waiting-description">
              <span className="Text Text--section">{message}</span>
            </div>
          </div>
        </div>
      </Delay>
    )
  }
}

WaitingPopup.propTypes = {
  message: PropTypes.string.isRequired,
  closePopup: PropTypes.func.isRequired
}
