import React from 'react';
import PropTypes from 'prop-types';

const DeclarationCardDeletable = ({ delete_confirm, declaration }) => (
  declaration.deletable ? (
    <span
      className="DeclarationsPerson__delete js-popup-open"
      data-popup-id={`delete_declaration_${declaration.slug}_confirmation`} >
      <i name="trash"
        title={delete_confirm}
        onClick={e => e.preventDefault()}
        className="Icons--close" />
    </span>
  ) : null
);

DeclarationCardDeletable.propTypes = {
  delete_confirm: PropTypes.string,
  declaration: PropTypes.object
};

export default DeclarationCardDeletable;
