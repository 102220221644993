import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlFor from '../../utils/url_helpers';
import TaxYearChoice from '../../components/tax_year_choice';
import WarningDeclarationPopUp from './warning_declaration_popup';

class NewDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      formAction: this.getFormAction(this.props)
    }
  }

  componentDidMount() {
    this.setState({
      formAction: urlFor(this.state.formAction)
    });
  }

  handleClick(e) {
    if(this.props.showWarning) {
      e.preventDefault();
    } else {
      this.setState( {disabled: true} )
    }
  }

  submitForm() {
    this.form.submit();
  }

  getFormAction(props) {
    if (props.singleTaxApp){
      return ("/app/declarations/new");
    }
      else {
        return ("/choose_tax_app");
    }
  }

  render() {
    const {year, personId, showWarning, taxApp, locales} = this.props;

    return (
      <div className={`DeclarationsPerson__fourColumns DeclarationsPerson__createYear js-popup-open year-${year}`}
           data-popup-id={`popUp-year_creation_form-${year}-${personId}`} >
        <form onSubmit={this.handleClick.bind(this)} className={`year_creation_form-${year}-${personId}`}
          action={this.state.formAction} ref={form => this.form = form} >
          <input name="person_id" type="hidden" value={personId || ''} readOnly />
          <input name="tax_year" type="hidden" value={year} readOnly />
          <input name="tax_app" type="hidden" value={taxApp} readOnly />
          <button disabled={this.state.disabled} type="submit" className="DeclarationsPerson__newYear">
            <div className="DeclarationsPerson__card" >
              <h3 className="Text--big Text--tax-year Heading">{year}</h3>
              <i name={`addYear-${year}`} className="Text--tax-year Text--wunder-green Icons--plus-circle" />
            </div>
          </button>
        </form>
        {showWarning && (
          <WarningDeclarationPopUp
            form={`year_creation_form-${year}-${personId}`}
            formSubmit={this.submitForm.bind(this)}
            locales={locales} /> )}
      </div>
    );
  }
}

NewDeclaration.propTypes = {
  locales: PropTypes.object,
  personId: PropTypes.string,
  showWarning: PropTypes.bool,
  taxApp: PropTypes.string,
  year: PropTypes.number
};

export default NewDeclaration;
