import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import debounce from 'lodash/debounce';

import { loadUpdatedContent } from '../shared/components/load_updated_content';

export default class ConfirmDraftButton extends Component {
  constructor(props) {
    super(props);

    this.state = { disabled: false };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const _this = this;
    const data = new FormData();
    const config = {
      headers: {
        'X-CSRF-Token': _this.props.csrfToken
      }
    }

    axios.put(_this.props.controllerUrl, data, config)
      .then((response) => {
        this.trackConfirmedDraft();
        this.setState({ disabled: true });

        // Reload the content of the page
        loadUpdatedContent(this.props.anchor);
      }).catch((error) => {
        this.setState({ disabled: false });
        console.log(error);
      });
  }

  trackConfirmedDraft() {
    const eventName = 'submission_draft_confirm';
    const eventProperties = {
      'category': 'submission',
      'label': this.props.daysSincePurchase,
      'user_agent': this.props.userAgent,
      'ip': this.props.ipAddress
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({ event: eventName, eventProperties });
    }
  }

  render() {
    return (
      <button
        onClick={debounce(this.handleSubmit, 250)}
        disabled={this.state.disabled}
        className={`Button Button--primary Button--md`}
      >
        {this.props.buttonMessage}
      </button>
    )
  }
}

ConfirmDraftButton.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  controllerUrl: PropTypes.string.isRequired,
  buttonMessage: PropTypes.string.isRequired,
  anchor: PropTypes.string,
}
