function urlFor(action) {
  let path = action;
  if (typeof window !== 'undefined') {
    let fullPath   = window.location.pathname;
    let segments   = fullPath.split('/');
    let appSegment = segments[1]; // weirdly enough the first is empty string???????

    if (appSegment === 'app' && !action.startsWith('/app')) {
      path = `/app${action}`;
    }
  }

  return path;
}

export default urlFor;
