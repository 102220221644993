import React from 'react';
import PropTypes from 'prop-types';

const StatusBox = ({ type = 'info', message }) => {
  const boxStyle = `StatusBox__${type}`
  return (
    <div className={`StatusBox ${boxStyle}`} hidden={!message}>
      <div className="StatusBox__title StatusBox__title--no-margin">
        {message}
      </div>
    </div>
  );
};

StatusBox.propTypes = {
  type: PropTypes.oneOf(['info', 'error']),
  message: PropTypes.string,
};

export default StatusBox;
