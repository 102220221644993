import React, {useRef, useEffect, useState} from "react"
import PropTypes from "prop-types"
import AdyenCheckout from "@adyen/adyen-web"
import StatusBox from "../adyen/components/status_box";
import TryAgain from "../adyen/components/try_again";
import {trackPaymentResult} from "../adyen/components/track_payment_result";

// Used to ping BE
const callServer = async (url, data) => {
  const res = await fetch(url, {
    method: "POST",
    body: data ? JSON.stringify(data) : "",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return await res.json();
}

const AdyenDropin = props => {
  const [showPayment, setShowPayment] = useState(true)
  const [price, setPrice] = useState(props.price);
  const [coupon, setCoupon] = useState(null);
  const [notification, setNotification] = useState({})
  const [showTryAgain, setShowTryAgain] = useState(false)
  const locales = props.locales;
  const currAdyenLocale = props.currentLocale === "de" ? "de-DE" : "en-US";
  const paymentContainer = useRef(null);

  useEffect(() => {
    document.addEventListener("voucher_price_changed", (e) => {
      setCoupon(e.detail.name);
      setPrice(e.detail.price * 100);
    })
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const redirectResult = queryParams.get("redirect_result") || queryParams.get("redirectResult")
    const sessionId = queryParams.get("session_id") || queryParams.get("sessionId")
    window.orderId = queryParams.get("order_id") || window.location.pathname.split("/").pop()

    if (redirectResult) {
      handleRedirect(sessionId, redirectResult);
    } else {
      startCheckout();
    }
    updateShowPayment(price)
  }, [price])

  // We don't show the payment component if the price is 100% OFF
  const updateShowPayment = (price) => {
    if (price === 0) {
      setShowPayment(false)
      $('#adyen-outer-container').hide()
    } else if (showPayment === false) {
      setShowPayment(true)
      $('#adyen-outer-container').show()
    }
  }

  const config = {
    clientKey: props.clientKey,
    locale: currAdyenLocale,
    environment: props.environment,
    showPayButton: true,
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        name: "Credit or debit card",
      },
      sepadirectdebit: {
        data: {
          ownerName: props.bankAccountDetails?.owner,
          ibanNumber: props.bankAccountDetails?.iban,
        },
      }
    },
    beforeSubmit: (data, component, actions) => {
      actions.resolve(data);
    },
    onPaymentCompleted: (result, component) => {
      handleServerResponse(result, component);
    },
    onError: (error, component) => {
      setNotification({type: 'error', message: locales.transaction_failed})
    }
  }
  const handleServerResponse = (result, component) => {
    trackPaymentResult(result)

    switch (result.resultCode) {
      case "Authorised":
        setNotification({type: 'info', message: locales.transaction_authorized})
        const redirectUrl = new URL('/app/adyen/mark_processing', window.location.origin)
        redirectUrl.searchParams.append('order_id', window.orderId)

        setTimeout(() => window.location.href = redirectUrl.href, 1000)
        break;
      case "Pending":
        setNotification({type: 'error', message: locales.transaction_pending})
        break;
      case "Received":
        setNotification({type: 'error', message: locales.transaction_received})
        break;
      case "Refused":
        setNotification({type: 'error', message: locales.transaction_declined})
        setShowTryAgain(true)
        break;
      default:
        setNotification({type: 'error', message: locales.transaction_declined})
        setShowTryAgain(true)
        break;
    }
  }

  const startCheckout = () => {
    const type = "dropin";
    try {
      const session_data = {
        payment_type: type,
        order_type: props.orderType,
        locale: props.currentLocale,
        coupon: coupon,
        value: price,
        quantity: props.quantity,
        uuid: props.uuid,
        channel: 'Web'
      }

      callServer("/app/adyen/sessions", session_data).then((data) => {
        window.orderId = data.session?.reference
        return createAdyenCheckout(data)
      }).then((checkout) => {
        if (paymentContainer.current) {
          if (window.adyenDropin)
            window.adyenDropin.unmount()
          window.adyenDropin = checkout.create(type)
          adyenDropin.mount(paymentContainer.current);
        } else {
          console.error("Adyen container not ready");
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleRedirect = (sessionId, redirectResult) => {
    AdyenCheckout({...config, session: {id: sessionId}})
      .then((checkout) => {
        checkout.submitDetails({details: {redirectResult: redirectResult}});
      });
  }

  const createAdyenCheckout = async ({session}) => {
    return new AdyenCheckout({...config, session});
  }

  return (
    <>
      {showPayment &&
        <>
          <div ref={paymentContainer} id="adyen_payment" className="payment"></div>
          <StatusBox {...notification}></StatusBox>
          {showTryAgain && <TryAgain retryUrl={props.successUrl}/>}
        </>
      }
    </>
  )
}


AdyenDropin.propTypes = {
  environment: PropTypes.string.isRequired,
  clientKey: PropTypes.string.isRequired,
  price: PropTypes.number,
  uuid: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  locales: PropTypes.object.isRequired,
  currentLocale: PropTypes.string,
  bankAccountDetails: PropTypes.shape({
    owner: PropTypes.string,
    iban: PropTypes.string,
  }),
};

export default AdyenDropin;
