import React from 'react';
import PropTypes from 'prop-types';

const DeclarationCardPartner = ({ joint_assessment, partner_person }) => (
  partner_person ? (
    <div
      className="DeclarationsPerson__partner"
      title={`${partner_person.first_name} (${joint_assessment})`}>
      <i className="Text--title Icons--persons" />
    </div>
  ) : null
);

DeclarationCardPartner.propTypes = {
  joint_assessment: PropTypes.string,
  partner_person: PropTypes.object
};

export default DeclarationCardPartner;
