import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FilesContainer extends Component {
  constructor(props) {
    super(props);
  }

  removeFileIcon(index) {
    const { removeFile } = this.props;

    return (
      <div>
        {this.props.afterRejection ?
          null :
          <div
            className="Icons Icons--stop Icons--wunder-red"
            onClick={(e) => removeFile(index)}>
          </div>
        }
      </div>
    )
  }

  rejectedInfo() {
    return (
      <div className="OnlineSubmission__rejected-message">
        {this.props.afterRejection ?
          <div className="Text Text--subinfo Text--wunder-red">
            { this.props.errorMessage }
          </div>
          : null
        }
      </div>
    )
  }

  render() {
    const { currentFiles, lastFiles } = this.props;

    return (
      <div className="OnlineSubmission__files-container">
        {
          currentFiles.map((fileObject, index) => {
            return (
              <div key={index} className="OnlineSubmission__file">
                <div className="Text Text--copy Text--wunder-grey">
                  {fileObject.name}
                </div>
                {
                  this.removeFileIcon(index)
                }
              </div>
            )
          })
        }
        {
          this.rejectedInfo()
        }
      </div>
    );
  }
}

FilesContainer.propTypes = {
  currentFiles: PropTypes.array.isRequired,
  removeFile: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  afterRejection: PropTypes.bool.isRequired
}
