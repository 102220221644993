import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TaxYearChoice from '../../components/tax_year_choice';
import WarningDeclarationPopUp from './warning_declaration_popup';

class ShowNewDeclarationYears extends Component {
  render() {
    const {locales} = this.props;

    return (
      <div className="DeclarationsPerson__fourColumns DeclarationsPerson__showDeclarations">
        <a href="#" className="DeclarationsPerson__showDeclarationsLink"
           onClick={this.props.toggleNewDeclarations} >
          <div className="DeclarationsPerson__card" >
            <h3 className="Text--big Text--highlighted Heading">{locales.showYears}</h3>
            <i name={`addYear-${locales.showYears}`} className="Text--tax-year Text--wunder-green Icons--chevron-right-circle" />
          </div>
        </a>
      </div>
    );
  }
}

ShowNewDeclarationYears.propTypes = {
  locales: PropTypes.object,
  toggleNewDeclarations: PropTypes.func
};

export default ShowNewDeclarationYears;
