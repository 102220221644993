import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OnlineSubmissionBox from './online_submission_box';
import PrintedSubmissionBox from './printed_submission_box';
import PrintedSubmissionNotAvailableBox from './printed_submission_not_available_box';
import SubmissionSwitchButton from './submission_switch_button';
import VerificationStateBox from './verification_state_box';

export default class SubmissionAlternatives extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onlineSubmission: !this.donePrinted()
    }

    this.donePrinted = this.donePrinted.bind(this);
    this.doneOrPending = this.doneOrPending.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.toggleSubmissionType = this.toggleSubmissionType.bind(this);
    this.onlineSubmissionScreens = this.onlineSubmissionScreens.bind(this);
    this.onlineSubmissionBox = this.onlineSubmissionBox.bind(this);
    this.submissionSwitchButton = this.submissionSwitchButton.bind(this);
    this.verificationStateBox = this.verificationStateBox.bind(this);
  }

  donePrinted() {
    return this.props.done && this.props.byPost;
  }

  doneOrPending() {
    return this.props.done || this.props.idPendingOrApproved;
  }

  submissionSwitchButton() {
    if (!(this.doneOrPending())) {
      return (
        <SubmissionSwitchButton
          onlineSubmission={this.state.onlineSubmission}
          onChange={this.toggleSubmissionType}
          messages={this.props.messages.toggle_button}
          type={this.props.type}
        />
      )
    }
  }

  renderSwitch() {
    if (this.state.onlineSubmission) {
      return this.onlineSubmissionScreens();
    } else {
      if (this.props.printedNotAvailable) {
        return (
          <PrintedSubmissionNotAvailableBox
            messages={this.props.messages}
            taxYear={this.props.taxYear}
          />
        )
      }
      else {
        return (
          <PrintedSubmissionBox
            messages={this.props.messages}
            submitDeclarationUrl={this.props.submitDeclarationUrl}
            summaryPathUrl={this.props.summaryPathUrl}
            csrfToken={this.props.csrfToken}
            done={this.props.done}
            taxOfficeAddress={this.props.taxOfficeAddress}
            daysSincePurchase={this.props.daysSincePurchase}
            userAgent={this.props.userAgent}
            ipAddress={this.props.ipAddress}
          />
        )
      }
    }
  }

  toggleSubmissionType() {
    this.setState({
      onlineSubmission: this.props.type != 'property_tax' ? !this.state.onlineSubmission : true
    });
  }

  onlineSubmissionScreens() {
    if (this.props.idPendingOrApproved || this.props.done) {
      return this.verificationStateBox();
    } else {
      return this.onlineSubmissionBox();
    }
  }

  verificationStateBox() {
    return (
      <VerificationStateBox
        csrfToken={this.props.csrfToken}
        messages={this.props.messages.verification}
        declarationsIndexUrl={this.props.declarationsIndexUrl}
        downloadDeclarationUrl={this.props.downloadDeclarationUrl}
        idState={this.props.idState}
        submittedAt={this.props.submittedAt}
        done={this.props.done}
        type={this.props.type}
        vermietet={this.props.vermietet}
        declarationPath={this.props.declarationPath}
      />
    )
  }

  onlineSubmissionBox() {
    return (
      <OnlineSubmissionBox
        apiKey={this.props.apiKey}
        csrfToken={this.props.csrfToken}
        messages={this.props.messages.online}
        popupMessages={this.props.messages.elster_download}
        onChange={this.toggleSubmissionType}
        s3Bucket={this.props.s3Bucket}
        s3Region={this.props.s3Region}
        identifyUrl={this.props.identifyUrl}
        uuid={this.props.uuid}
        person_uuid={this.props.person_uuid}
        idState={this.props.idState}
        lastFiles={this.props.lastFiles}
        pickerLocale={this.props.pickerLocale}
        filestackSecurity={this.props.filestackSecurity}
        daysSincePurchase={this.props.daysSincePurchase}
        userAgent={this.props.userAgent}
        ipAddress={this.props.ipAddress}
        type={this.props.type}
        sumsub_token={this.props.sumsub_token}
        taxYear={this.props.taxYear}
      />
    )
  }

  render() {
    return (
      <div>
        {
          this.submissionSwitchButton()
        }
        {
          this.renderSwitch()
        }
      </div>
    );
  }
}

SubmissionAlternatives.propTypes = {
  apiKey: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  submitDeclarationUrl: PropTypes.string.isRequired,
  summaryPathUrl: PropTypes.string.isRequired,
  downloadDeclarationUrl: PropTypes.string.isRequired,
  identifyUrl: PropTypes.string.isRequired,
  filestackSecurity: PropTypes.object.isRequired,
  done: PropTypes.bool.isRequired,
  idPendingOrApproved: PropTypes.bool.isRequired,
  idState: PropTypes.string,
  byPost: PropTypes.bool.isRequired,
  taxOfficeAddress: PropTypes.string.isRequired,
  stepNumber: PropTypes.string.isRequired,
  s3Bucket: PropTypes.string.isRequired,
  s3Region: PropTypes.string.isRequired,
  submittedAt: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  person_uuid: PropTypes.string.isRequired,
  lastFiles: PropTypes.array,
  pickerLocale: PropTypes.string.isRequired,
  printedNotAvailable: PropTypes.bool.isRequired,
  taxYear: PropTypes.number.isRequired,
  vermietet: PropTypes.bool.isRequired,
  sumsub_token: PropTypes.string.isRequired,
  declarationPath: PropTypes.string.isRequired
}
