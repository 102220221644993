import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Title from '../../shared/components/title';

import NpsOptions from './nps_options'

export default class ScoreScreen extends Component {

  handleSubmit(score) {
    const data = new FormData();
    data.append('uuid', this.props.npsVotingUuid);
    data.append('voting', score);

    const config = {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': this.props.csrfToken
      }
    }

    axios.put(this.props.postUrl, data, config).then((response) => {
      this.props.handleScoreChange(score)
    });
  }

  render() {
    const { messages } = this.props;

    return (
      <div id='score_screen'>
        <Title title={messages.description} />
        <div className="NpsPopup__breakline"></div>
        <div className="NpsPopup__score-labels-mobile">
          <div className="Text Text--wunder-grey">{messages.instruction}</div>
          <div className="NpsPopup__score-label-top">
            <div className="Text"><span className="Text Text--wunder-grey">10 </span>{messages.positive_label}</div>
          </div>
        </div>
        <NpsOptions onClick={this.handleSubmit.bind(this)} />
        <div className="NpsPopup__score-labels-desktop">
          <div className="Text">{messages.negative_label}</div>
          <div className="Text Text--wunder-grey">{messages.instruction}</div>
          <div className="Text">{messages.positive_label}</div>
        </div>
        <div className="NpsPopup__score-labels-mobile">
          <div className="NpsPopup__score-label-bottom">
            <div className="Text"><span className="Text Text--wunder-grey">0 </span>{messages.negative_label}</div>
          </div>
        </div>
      </div>
    );
  }
}

ScoreScreen.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired,
  handleScoreChange: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  npsVotingUuid: PropTypes.string
}
