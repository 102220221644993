import React, { Component } from 'react';
import PropTypes from 'prop-types';

function getValue(value) {
  if (value) {
    var stringValue = value.replace(/[^\d.-]/g, '');
    var floatValue = parseFloat(stringValue).toFixed(2);
    return floatValue;
  } else {
    return value;
  }
}

function getValueClass(value, status) {
  var classValue = 'DeclarationsPerson__tax-refund--' + status;
  if (value < 0) {
    classValue = 'DeclarationsPerson__tax-refund--negative';
  }
  return classValue;
}

function floatToCurrency(value) {
  value = value.replace(".", ",");
  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `${value} €`;
}

class TaxRefund extends Component {
  render() {
    const valueAmount = getValue(this.props.valueString);
    const valueClass = getValueClass(valueAmount, this.props.status);
    const valueCurrency = valueAmount ? floatToCurrency(valueAmount) : valueAmount;
    return (
      <h4 className={`Text--highlighted Text--font-book ${valueClass}`}>
        {valueCurrency}
      </h4>
    );
  }
}

TaxRefund.propTypes = {
  valueString: PropTypes.string,
  status: PropTypes.string
};

export default TaxRefund;
