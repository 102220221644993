import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlFor from '../../utils/url_helpers';

class NewPropertyTax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      formAction: this.getFormAction(this.props)
    }
  }

  componentDidMount() {
    this.setState({
      formAction: urlFor(this.state.formAction)
    });
  }

  handleClick(e) {
    if(this.props.showWarning) {
      e.preventDefault();
    } else {
      this.setState( {disabled: true} )
    }
  }

  submitForm() {
    this.form.submit();
  }

  getFormAction(props) {
      return ("/app/property_taxes/new");
  }

  render() {
    const {personId, taxApp, locales} = this.props;

    return (
      <div className={`DeclarationsPerson__fourColumns DeclarationsPerson__createYear js-popup-open`}
           data-popup-id={`popUp-year_creation_form--${personId}`} >
        <form onSubmit={this.handleClick.bind(this)} className={`year_creation_form-2022-${personId}`}
              action={this.state.formAction} ref={form => this.form = form} >
          <input name="person_id" type="hidden" value={personId || ''} readOnly />
          <input name="tax_app" type="hidden" value={taxApp} readOnly />
          <button disabled={this.state.disabled} type="submit" className="DeclarationsPerson__newYear">
            <div className="DeclarationsPerson__card" >
              <h3 className="Text--big Text--tax-year Heading">{locales.property_tax}</h3>
              <i name={`addYear-2022`} className="Text--tax-year Text--wunder-green Icons--plus-circle" />
            </div>
          </button>
        </form>
      </div>
    );
  }
}

NewPropertyTax.propTypes = {
  locales: PropTypes.object,
  personId: PropTypes.string,
  taxApp: PropTypes.string,
};

export default NewPropertyTax;
