import React, { Component } from 'react';

export default class Title extends Component {

  render() {
    return (
      <div className="Popup__title-margin-mobile">
        <header className="Heading Heading--3a">
          {this.props.title}
        </header>
      </div>
    );
  }
}
