import axios from 'axios';

// Replace content of the current page to the
// another one page that was loaded via the ajax request
// Idea behind this is to provide a page with enabled
// second step without refreshing entire page
export const loadUpdatedContent = (anchor = undefined) => {
  const url = window.location.href.replace(/#.*$/, "");

  axios.get(url, { dataType: 'html' }).then( (response) => {

    // Replace page contents with html from response
    const plainHtml = response.data;
    $('.Page').html($('.Page > *', $(plainHtml)));

    // In case popup was active before reload, deactivate
    // css state of the body to no active (mostly for scrolling)
    $('body').removeClass('js-popup-active');

    // Mount React components
    ReactOnRails.reactOnRailsPageLoaded();

    // Execute function once after 200 milliseconds: give time to the browser
    // for parse and attach html
    if(anchor !== undefined) {
      setTimeout(function () {
        window.scrollTo(10, $('#' + anchor).position().top + 90);
      }, 200);
    }
  });
}
