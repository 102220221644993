import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { loadUpdatedContent } from '../../shared/components/load_updated_content';

import SubmitPopups from '../../shared/components/submit_popups';
import ConfirmationPopup from '../../shared/components/confirmation_popup';

export default class OnlineSubmit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      popupVersion: 1,
      showConfirmation: false,
      showUnavailable: false
    }

    this.finalizeButtonCssClass = this.finalizeButtonCssClass.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.toggleUnavailable = this.toggleUnavailable.bind(this);
    this.submissionUnavailable = this.submissionUnavailable.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  closePopup() {
    this.setState({
      showPopup: false,
      popupVersion: 1
    });
  }

  // Submission is not available if the declaration tax year is the same as the current timestamp year
  submissionUnavailable() {
    return (this.props.taxYear === new Date().getUTCFullYear())
  }

  onButtonClick() {
    this.submissionUnavailable() ?  this.toggleUnavailable() : this.toggleConfirmation()
  }

  finalizeButtonCssClass() {
    const defaultClass = `Button Button--primary Button--md ${this.props.buttonStyleOutlined ? 'Button--outlined-green' : ''}`;
    let additionalClass;

    if ((this.filesCount() > 0 && !this.props.afterRejection) || (this.props.buttonEnabled)) {
      additionalClass = '';
    } else {
      additionalClass = 'Button--hidden';
    }

    return `${defaultClass} ${additionalClass}`;
  }

  filesCount() {
    return this.props.files.length;
  }

  toggleConfirmation() {
    this.setState((prevState) => {
      return { showConfirmation: !prevState.showConfirmation }
    });
  }

  toggleUnavailable() {
    this.setState((prevState) => {
      return { showUnavailable: !prevState.showUnavailable }
    });
  }

  handleSubmit() {
    const _this = this;

    const data = new FormData();
    data.append('declaration_uuid', _this.props.uuid);
    data.append('person_uuid', _this.props.person_uuid);
    data.append('files', JSON.stringify(_this.props.files));
    _this.props.files.forEach( (file, index) => data.append(`file_data[${index}]`, file.originalFile))


    const config = {
      headers: {
        'X-CSRF-Token': _this.props.csrfToken
      }
    }

    this.setState({ showConfirmation: false });
    this.setState({ showUnavailable: false });

    // It turns on waiting behaviours, calls controller and updates the page
    _this.setState({ showPopup: true }, () => {
      axios.post(_this.props.submitUrl, data, config)
        .then((response) => {

          if (response.data.success === true) {

            if (this.filesCount() > 0) { this.trackVerificationUpload(); };
            this.trackSubmission();

            // Reload the content of the page
            loadUpdatedContent();
          } else {
            _this.setState({ popupVersion: 2 })
          }

        }).catch((error) => {

          console.log(error);
          _this.setState({ popupVersion: 2 })

        });
    })
  }

  trackVerificationUpload() {
    const eventName = 'taxpayer_verification_upload';
    const eventProperties = {
      'category': 'submission',
      'days_since_purchase': this.props.daysSincePurchase,
      'user_agent': this.props.userAgent,
      'ip': this.props.ipAddress
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({event: eventName, eventProperties});
    }
  }

  trackSubmission() {
    const eventName = 'submission_online_sent';
    const eventProperties = {
      'category': 'submission',
      'days_since_purchase': this.props.daysSincePurchase,
      'user_agent': this.props.userAgent,
      'ip': this.props.ipAddress
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({event: eventName, eventProperties});
    }
  }

  render() {
    return (
      <>
        <button
          className={this.finalizeButtonCssClass()}
          onClick={this.onButtonClick}
        >
          {this.props.buttonLabel}
        </button>
        {this.state.showUnavailable ?
          <div>
            <ConfirmationPopup
              onSubmit={this.toggleUnavailable}
              messages={this.props.popupMessages.unsubmittable}
              closePopup={this.toggleUnavailable}
            />
          </div> : null
        }
        {this.state.showConfirmation ?
          <div>
            <ConfirmationPopup
              onSubmit={this.handleSubmit}
              messages={this.props.popupMessages.confirmation}
              closePopup={this.toggleConfirmation}
            />
          </div>
          : null
        }
        <SubmitPopups
          showPopups={this.state.showPopup}
          popupVersion={this.state.popupVersion}
          popupMessages={this.props.popupMessages}
          closePopup={this.closePopup}
        />
      </>
    );
  }
}

OnlineSubmit.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonEnabled: PropTypes.bool,
  files: PropTypes.array.isRequired,
  uuid: PropTypes.string.isRequired,
  person_uuid: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  afterRejection: PropTypes.bool.isRequired,
  buttonStyleOutlined: PropTypes.bool,
  popupMessages: PropTypes.object.isRequired,
  taxYear: PropTypes.number.isRequired,
}
