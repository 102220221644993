import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Title from '../../shared/components/title';

export default class ThankYouScreen extends Component {

  render() {
    const { messages } = this.props;

    return (
      <div id="thank_you_screen" className="NpsPopup__thank-you-screen">
        <div className="NpsPopup__column-left">
          <Title title={messages.title} />
          <div className="NpsPopup__comment-label">
            <span className="Text Text--section">{messages.description}</span>
          </div>
        </div>
        <div className="NpsPopup__column-right"></div>
      </div>
    );
  }
}

ThankYouScreen.propTypes = {
  messages: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  })
}
