import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ClosePopup from '../../shared/components/close_popup';

export default class ConfirmationPopup extends Component {
  render() {
    const { messages, onSubmit, closePopup } = this.props;

    return (
      <div className="Popup Popup--overlay Popup--visible">
        <div className="Popup__content">
          <ClosePopup closePopup={closePopup} />
          <div className="Popup__info">
            <span className="Text Text--copy">
              {messages.description}
            </span>
          </div>
          <div className="Popup__buttons">
            <button onClick={onSubmit} className="Link Link--button-reset Link--primary Link--uppercase Link--no-underline">
              <span className="Text Text--copy">
                {messages.button}
              </span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

ConfirmationPopup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  messages: PropTypes.shape({
    description: PropTypes.string,
    button: PropTypes.string
  })
}
