import React, { Component } from 'react';

export default class Tooltip extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <a href="javascript:;"
        className="js-popup-open js-tracking-click TopBar__link TopBar__link--icon input-tooltip"
        data-popup-id={`field_info_modal_${this.props.name}`}>
        <span className="Icons--info"></span>
      </a>
    )
  }
}
