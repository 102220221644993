import React from 'react';
import PropTypes from 'prop-types';

function getIconByStatus(status) {
  switch (status) {
    case `fill_out_data`:
    case `ready_to_submit`:
    case `paid`:
    case `draft_downloaded`:
    case `draft_confirmed`:
    case `documents_being_reviewed`:
      return 'chevron-right-circle';
    case `check_summary_page`:
    case `documents_have_been_rejected`:
      return 'warn-circle';
    case `documents_have_been_approved`:
    case `property_tax_submitted`:
    case `submitted`:
    case `tax_notice_received`:
      return 'arrow-down-circle';
    default:
      return 'chevron-right-circle';
  }
}

const DeclarationCardStatus = ({ locales, declaration }) => (
  <div
    className={`DeclarationsPerson__cardFooter DeclarationsPerson__${declaration.status}`}
    title={locales[`status_tip_${declaration.status}`]} >
    <span>{locales[`status_${declaration.status}`]}</span>
  </div>
);

DeclarationCardStatus.propTypes = {
  locales: PropTypes.object,
  declaration: PropTypes.object
};

export default DeclarationCardStatus;
