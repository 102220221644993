import React, { Component } from 'react';

import PasswordErrors from '../user_password/components/password_errors';

export default class UserPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || '',
      touched: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleFocus(event){
    this.setState({touched: true})
  }

  render() {
    const { translations, validations, label } = this.props;
    const { value, touched } = this.state;

    return (
      <>
        <input
          width="100%"
          name="user[password]"
          id="user_password"
          type="password"
          className="field__input"
          placeholder={translations.password}
          autoComplete="off"
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
        />

        {
          (touched) ?
          <PasswordErrors
            value={value}
            translations={translations.password_errors}
            validations={validations}
          />
          : null
        }
      </>
    );
  }
}
