import React from 'react';

const TryAgain = ({ retryUrl }) => {
  const css = 'Button Button--full-width Button--smaller-margin-bottom Button--smaller-padding Button--contextual-tertiary'
  const reload = () => {
    window.location.replace(retryUrl);
  }
  return (
    <a className={css} onClick={reload}>
      Try again
    </a>
  )
}

export default TryAgain;
