import React, { Component } from 'react';

export default class ClosePopup extends Component {
  render() {
    return (
      <div>
        {
          this.props.closePopup ?
          <div
            className="Popup__close"
            onClick={this.props.closePopup}
          >
            <span className="Icons Icons--close"></span>
          </div>
          : null
        }
      </div>
    );
  }
}
