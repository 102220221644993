// Tracks only failed payments. Successful payments are triggered though backend /adyen/shopper_redirect hit
export const trackPaymentResult = (adyenServerResponse) => {
  try {
    const hasGTM = typeof window.dataLayer !== 'undefined';
    const resultCode = adyenServerResponse.resultCode
    if (!hasGTM || resultCode === 'Authorised') return

    const eventProperties = {
      event: 'purchase_adyen_failed',
      result_code: resultCode
    }
    window.dataLayer.push(eventProperties);
  } catch (e) {
    console.error(e)
  }
}
