import React, { Component } from 'react';

import TaxYearChoice from './tax_year_choice'

export default class NewDeclarationButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false
    };
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  render() {
    return (
      <div>
        <button
          className="Button Button--tertiary Button--full-width-mobile"
          onClick={this.togglePopup.bind(this)}
        >
          {this.props.label}
        </button>

        {
          this.state.showPopup ?
          <TaxYearChoice
            showPopup={true}
            arbeitnehmer={this.props.arbeitnehmer}
            supportedTaxYears={this.props.supportedTaxYears}
            closePopup={this.togglePopup.bind(this)}
            messages={this.props.messages}
            taxApp={this.props.taxApp}
          />
          : null
        }
      </div>
    );
  }
}
