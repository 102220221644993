import React, {Component} from 'react';

export default class TopBarTax extends Component {
  constructor(props) {
    super(props);
  }

  renderLink(data) {
    return (
      <a href="javascript:;"
         className="js-popup-open js-tracking-click TopBar__link TopBar__link--icon"
         data-popup-id="tax_calculator_modal"
         data-event-name={data['event-name']}
         data-event-properties={JSON.stringify(data['event-properties'])}>
        <span className="Icons--info"></span>
      </a>
    )
  }

  renderMandatoryLink(data) {
    return (
      <a href="javascript:;"
         className="js-popup-open js-tracking-click TopBar__link TopBar__mandatory_tax_return_link TopBar__link--icon"
         data-popup-id="mandatory_tax_return"
         data-event-name={data['event-name']}
         data-event-properties={JSON.stringify(data['event-properties'])}>
        <span className="Icons--warn-circle"></span>
      </a>
    )
  }

  render() {
    let mandatory_tax_return;
    if (this.props.mandatory_tax_return) {
      mandatory_tax_return = <span className="TopBar__mandatory_tax_return_text">
        {this.renderMandatoryLink(this.props.data)}
        {this.props.mandatory_tax_return_text}
      </span>;
    } else {
      mandatory_tax_return = null;
    }

    return (
      <div className="TopBar__declaration_info">
        <div>
          <div>
            <span className="TopBar--desktop_only TopBar--margin-right">
              {this.props.tax_year_text}
            </span>
            <span>
              {this.props.tax_year}
            </span>
          </div>
          <div style={{display: 'flex'}}>
            <span className="TopBar--desktop_only TopBar--margin-right">
              {this.props.label}
            </span>
            <span className={`TopBar--underlined ${this.props.amount_css_class}`}>
            {this.props.tax_amount}
            </span>
          </div>

          <div>
            {mandatory_tax_return}
            <span className="TopBar__loss_carry_forward_tip">
              {this.props.loss_carry_forward_tip}
            </span>
          </div>
        </div>
        <div>
          {this.renderLink(this.props.data)}
        </div>
      </div>
    )
  }
}
