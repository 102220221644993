import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SubmissionAlternatives from '../submission_step/components/submission_alternatives';
import SkipVerification from '../submission_step/components/skip_verification';

/* STEP 3 */
export default class SubmissionStep extends Component {
  constructor(props) {
    super(props);
    this.state = { submitDone: props.done }
    this.boxCssClass = this.boxCssClass.bind(this);
    this.statusCssClass = this.statusCssClass.bind(this);
    this.submissionAlternatives = this.submissionAlternatives.bind(this);
    this.headingSwitch = this.headingSwitch.bind(this);
    this.contentSwitch = this.contentSwitch.bind(this);
    this.inactive = this.inactive.bind(this);
    this.skipVerification = this.skipVerification.bind(this);
  }

  headingSwitch() {
    const { done, active, messages, byPost, fullName } = this.props;
    let personVerified = this.props.personVerified;

    if (done && byPost) {
      return (
        <>
          <div className="Text Text--title Text--white">
            {messages.printed.heading} - {fullName}
          </div>
        </>

      );
    } else if (active && ((this.props.jointDeclaration && !personVerified) || !this.props.jointDeclaration)) {
      return (
        <div className={`Heading Heading--margin-bottom Heading--3 ${done ? "Heading--white" : "Heading--green"}`}>
          {this.props.type === 'property_tax' ? messages.printed.pt_heading : messages.printed.heading} - {fullName}
        </div>
      );
    } else if (personVerified && this.props.jointDeclaration && this.props.currentStepNumber != '4') {
      return (
          <div className={`Text Text--title ${done ? "Text--white" : "Text--blue"}`}>
            <span className="Text Text--title Text--white">
              {messages.printed.heading} - {fullName}
            </span>
            <div className="WizardSteps__thumb-up-icon" >
              <div className="Icons Icons--thumb_up"></div>
            </div>
          </div>
      );
    } else if (personVerified && this.props.jointDeclaration && this.props.currentStepNumber == '4' && done) {
      return (
          <div className={`Text Text--title ${done ? "Text--white" : "Text--blue"}`}>
            <span className="Text Text--title Text--white">
              {messages.printed.heading} - {fullName}
            </span>
            <div className="WizardSteps__thumb-up-icon" >
              <div className="Icons Icons--thumb_up"></div>
            </div>
          </div>
      );
    } else {
      return (
        <div className={`${done ? "Text Text--title Text--white" : "Heading--green"}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span className={`${done ? "Text Text--title Text--white" : "Heading--3 Heading--green Heading--with-padding"}`}>
            {this.props.type === 'property_tax' ? messages.printed.pt_heading : messages.printed.heading} - {fullName}
          </span>
          <div className="WizardSteps__thumb-up-icon" style={{ color: 'white'}}>
            <div className="Icons Icons--thumb_up"></div>
          </div>
        </div>
      );
    }
  }

  contentSwitch() {
    const { done, messages, personVerified } = this.props;

    if (this.inactive()) {
      return (
        <div className={`Text Text--copy ${done ? "Text--white" : "Text--blue"}`}>
          {messages.online.description_on_inactive}
        </div>
      )
    } else {
      if (personVerified && !done) {
        return this.skipVerification();
      } else {
        return this.submissionAlternatives();
      }
    }
  }

  skipVerification() {
    return (
      <SkipVerification
        uuid={this.props.uuid}
        person_uuid={this.props.person_uuid}
        csrfToken={this.props.csrfToken}
        submitUrl={this.props.submitOnlineDeclarationUrl}
        messages={this.props.messages.skip_verification}
        popupMessages={this.props.popupMessages}
        daysSincePurchase={this.props.daysSincePurchase}
        userAgent={this.props.userAgent}
        ipAddress={this.props.ipAddress}
        jointDeclaration={this.props.jointDeclaration}
        stepNumber={this.props.stepNumber}
        currentStepNumber={this.props.currentStepNumber}
        firstPersonVerified={this.props.firstPersonVerified}
        taxYear={this.props.taxYear}
      />
    )
  }

  submissionAlternatives() {
    return (
      <SubmissionAlternatives
        apiKey={this.props.apiKey}
        csrfToken={this.props.csrfToken}
        messages={this.props.messages}
        submitDeclarationUrl={this.props.submitDeclarationUrl}
        submitOnlineDeclarationUrl={this.props.submitOnlineDeclarationUrl}
        downloadDeclarationUrl={this.props.downloadDeclarationUrl}
        summaryPathUrl={this.props.summaryPathUrl}
        declarationsIndexUrl={this.props.declarationsIndexUrl}
        identifyUrl={this.props.identifyUrl}
        done={this.props.done}
        idPendingOrApproved={this.props.idPendingOrApproved}
        idState={this.props.idState}
        byPost={this.props.byPost}
        taxOfficeAddress={this.props.taxOfficeAddress}
        stepNumber={this.props.stepNumber}
        s3Bucket={this.props.s3Bucket}
        s3Region={this.props.s3Region}
        submittedAt={this.props.submittedAt}
        uuid={this.props.uuid}
        person_uuid={this.props.person_uuid}
        lastFiles={this.props.lastFiles}
        filestackSecurity={this.props.filestackSecurity}
        pickerLocale={this.props.pickerLocale}
        printedNotAvailable={this.props.printedNotAvailable}
        daysSincePurchase={this.props.daysSincePurchase}
        userAgent={this.props.userAgent}
        ipAddress={this.props.ipAddress}
        taxYear={this.props.taxYear}
        type={this.props.type}
        vermietet={this.props.vermietet}
        sumsub_token={this.props.sumsub_token}
        declarationPath={this.props.declarationPath}
      />
    )
  }

  statusCssClass() {
    const { active, done } = this.props;

    let doneVerified = done
    if (this.props.jointDeclaration) {
      doneVerified = (this.props.currentStepNumber != '4' && this.props.personVerified) || done
    }

    const current = active ? 'WizardSteps__step-status--current' : '';
    const checked = doneVerified ? 'WizardSteps__step-status--checked' : '';
    const defaultClass = 'WizardSteps__step-status'

    return `${defaultClass} ${current} ${checked} `;
  }

  boxCssClass() {
    const { active, done } = this.props;
    let inactiveDone = this.inactive();

    if (this.props.jointDeclaration) {
      inactiveDone = (this.props.currentStepNumber != '4' && this.props.personVerified) || done
    }
    const doneClass = done ? 'Box--filled' : 'Box--outlined-green';
    const defaultClass = 'Box Box--outlined Box--no-margin';

    return `${defaultClass} ${doneClass} `;
  }

  inactive() {
    const { active, done } = this.props;

    return (active || done) ? false : true;
  }

  render() {
    const { stepNumber } = this.props;

    return (
      <div>
        <a className="Anchor" id="step3"></a>
        <div className="WizardSteps__step">
          <div className={this.statusCssClass()}>
            <div className="WizardSteps__step-label">{stepNumber}</div>
          </div>
          <div className="WizardSteps__step-content WizardSteps__step-content--last">
            <div className={this.boxCssClass()}>
              {
                this.headingSwitch()
              }
              {
                this.contentSwitch()
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SubmissionStep.propTypes = {
  apiKey: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  popupMessages: PropTypes.object.isRequired,
  submitDeclarationUrl: PropTypes.string.isRequired,
  submitOnlineDeclarationUrl: PropTypes.string.isRequired,
  downloadDeclarationUrl: PropTypes.string.isRequired,
  summaryPathUrl: PropTypes.string.isRequired,
  declarationsIndexUrl: PropTypes.string.isRequired,
  identifyUrl: PropTypes.string.isRequired,
  done: PropTypes.bool.isRequired,
  idPendingOrApproved: PropTypes.bool.isRequired,
  idState: PropTypes.string,
  personVerified: PropTypes.bool.isRequired,
  byPost: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  taxOfficeAddress: PropTypes.string.isRequired,
  stepNumber: PropTypes.string.isRequired,
  s3Bucket: PropTypes.string.isRequired,
  s3Region: PropTypes.string.isRequired,
  submittedAt: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  person_uuid: PropTypes.string.isRequired,
  lastFiles: PropTypes.array,
  filestackSecurity: PropTypes.object.isRequired,
  pickerLocale: PropTypes.string.isRequired,
  printedNotAvailable: PropTypes.bool.isRequired,
  fullName: PropTypes.string.isRequired,
  jointDeclaration: PropTypes.bool.isRequired,
  currentStepNumber: PropTypes.string.isRequired,
  firstPersonVerified: PropTypes.bool,
  taxYear: PropTypes.number.isRequired,
  vermietet: PropTypes.bool.isRequired,
  sumsub_token: PropTypes.string.isRequired,
  declarationPath: PropTypes.string.isRequired
}
