import React, { Component } from 'react';
import axios from 'axios';
import Delay from 'react-delay';
import PropTypes from 'prop-types';

import ClosePopup from '../shared/components/close_popup';

import UserOriginScreen from '../nps_popup/components/user_origin_screen';
import ScoreScreen from '../nps_popup/components/score_screen';
import CommentScreen from '../nps_popup/components/comment_screen';
import ThankYouScreen from '../nps_popup/components/thank_you_screen';

const UserOriginScreenNumber = 1;
const ScoreScreenNumber = 2;
const CommentScreenNumber = 3;
const ThankYouScreenNumber = 4;

export default class NpsPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: UserOriginScreenNumber,
      comment: '',
      showPopup: this.props.showPopup,
      npsVotingUuid: null
    }

    this.renderSwitch = this.renderSwitch.bind(this);
  }

  closePopup() {
    this.setState({
      showPopup: false
    });
  }

  dismissPopup() {
    const data = new FormData();
    data.append('dismissed', '1');

    const config = {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': this.props.csrfToken
      }
    }

    // Creating new nps voting only if it wasn't already created
    if (this.state.npsVotingUuid) {
      data.append('uuid', this.state.npsVotingUuid);
      axios.put(this.props.postUrl, data, config);
    } else {
      axios.post(this.props.postUrl, data, config);
    }

    this.setState({
      showPopup: false
    });
  }

  handleUserOriginChange(npsVotingUuid) {
    this.setState({npsVotingUuid: npsVotingUuid, screen: ScoreScreenNumber});
  }

  handleScoreChange(score) {
    this.trackAddedScore(score);
    this.setState({screen: CommentScreenNumber});
  }

  handleCommentChange(event) {
    this.trackAddedComment(event.target.value);
    this.setState({comment: event.target.value});
  }

  handleCommentSubmit(e) {
    e.preventDefault();

    const data = new FormData();
    data.append('uuid', this.state.npsVotingUuid);
    data.append('reason', this.state.comment);

    const config = {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': this.props.csrfToken
      }
    }

    axios.put(this.props.postUrl, data, config).then((response) => {
      this.setState({screen: ThankYouScreenNumber})
    });
  }

  trackAddedScore(score) {
    const eventName = 'feedback_submission';
    const eventProperties = {
      'category': 'feedback',
      'source': this.props.trackingNpsSource,
      'voting': score,
      'user_agent': this.props.userAgent,
      'ip': this.props.ipAddress
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({event: eventName, eventProperties});
    }
    if (typeof mixpanel !== 'undefined') {
      mixpanel.track(eventName, eventProperties);
    }
  }

  trackAddedComment(comment) {
    const eventName = 'feedback_notice';
    const eventProperties = {
      'category': 'feedback',
      'source': this.props.trackingNpsSource,
      'reason': comment,
      'user_agent': this.props.userAgent,
      'ip': this.props.ipAddress
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({event: eventName, eventProperties});
    }
  }

  renderSwitch(screen) {
    switch(screen) {
      case UserOriginScreenNumber:
        return this.userOriginScreen();
      case ScoreScreenNumber:
        return this.scoreScreen();
      case CommentScreenNumber:
        return this.commentScreen();
      case ThankYouScreenNumber:
        return this.thankYouScreen();
      default:
        return null;
    };
  }

  userOriginScreen() {
    return (
      <UserOriginScreen
        csrfToken={this.props.csrfToken}
        postUrl={this.props.postUrl}
        messages={this.props.messages.user_origin_screen}
        handleUserOriginChange={this.handleUserOriginChange.bind(this)}
        submissionType={this.props.submissionType}
        availableOrigins={this.props.availableOrigins}
        personVerified={this.props.personVerified}
      />
    )
  }

  scoreScreen() {
    return (
      <ScoreScreen
        csrfToken={this.props.csrfToken}
        postUrl={this.props.postUrl}
        messages={this.props.messages.score_screen}
        handleScoreChange={this.handleScoreChange.bind(this)}
        npsVotingUuid={this.state.npsVotingUuid}
      />
    )
  }

  commentScreen() {
    return (
      <CommentScreen
        messages={this.props.messages.comment_screen}
        onChange={this.handleCommentChange.bind(this)}
        onSubmit={this.handleCommentSubmit.bind(this)}
        commentLength={this.state.comment.length}
      />
    )
  }

  thankYouScreen() {
    return (
      <ThankYouScreen
        messages={this.props.messages.thank_you_screen}
      />
    )
  }

  render() {
    const waitingTime = this.props.waitingTime || 3000; // 3 Seconds
    const currentScreen = this.state.screen;
    const closingPopupLogic = (currentScreen === ThankYouScreenNumber ? this.closePopup.bind(this) : this.dismissPopup.bind(this));

    return (
      <Delay wait={waitingTime}>
        {this.state.showPopup ?
          <div className="NpsPopup">
            <div className="Popup Popup--overlay Popup--visible">
              <div className="Popup__content Popup__content--wide">
                <ClosePopup closePopup={closingPopupLogic} />
                { this.renderSwitch(currentScreen) }
              </div>
            </div>
          </div>
          : null
        }
      </Delay>
    );
  }
}

NpsPopup.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  showPopup: PropTypes.bool,
  userAgent: PropTypes.string,
  waitingTime: PropTypes.number,
  trackingNpsSource: PropTypes.string,
  submissionType: PropTypes.string.isRequired,
  availableOrigins: PropTypes.array.isRequired,
  personVerified: PropTypes.bool
}
