import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlFor from '../../utils/url_helpers';

class DeleteDeclarationPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formAction: `/declarations/${this.props.slug}`
    }
  }

  componentDidMount() {
    this.setState({
      formAction: urlFor(this.state.formAction)
    });
  }

  render() {
    const { authenticityToken, deleteConfirmText, deleteConfirmationText, slug } = this.props;

    return (
      <div
        id={`delete_declaration_${slug}_confirmation`}
        className="js-popup Popup Popup--overlay"
      >
        <div className="Popup__content">
          <div className="Popup__close">
            <span className="js-popup-close Icons Icons--close"></span>
          </div>
          <div className="Popup__info">
            <span className="Text Text--copy">{deleteConfirmationText}</span>
          </div>
          <form action={this.state.formAction} acceptCharset="UTF-8" method="post">
            <input type='hidden' name='authenticity_token' value={authenticityToken} />
            <input type="hidden" name="_method" value="delete" />
            <div className="Popup__buttons">
              <input
                type="submit"
                name="commit"
                value={deleteConfirmText}
                className="Link Link--button-reset Link--secondary Link--uppercase Link--no-underline"
                data-disable-with={deleteConfirmText}
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

DeleteDeclarationPopUp.propTypes = {
  authenticityToken: PropTypes.string,
  deleteConfirmationText: PropTypes.string,
  deleteConfirmText: PropTypes.string,
  slug: PropTypes.string
};

export default DeleteDeclarationPopUp;
