import React, { Component } from 'react';

export default class TaxYearOption extends Component {
  render() {
    return (
      <div
        onClick={() => this.props.onTaxYearSelect(this.props.taxYear)}
        className={ `Button Button--${this.props.active ? 'chosen' : 'secondary'} TaxYearChoice__option ` }
      >
        <div className="Text Text--subtitle">
          {this.props.taxYear}
        </div>
      </div>
    );
  }
}
