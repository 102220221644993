import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PrintedSubmissionNotAvailableBox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { messages, taxYear } = this.props;

    if(taxYear >= 2021) {
      return (
        <div>
          <div className="Text Text--copy Text--blue Text--with-margin">
            { messages.printed_not_available.description_2021 }
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="Text Text--copy Text--blue Text--with-margin">
            { messages.printed_not_available.description }
          </div>
        </div>
      );
    }
  }
}

PrintedSubmissionNotAvailableBox.propTypes = {
  messages: PropTypes.object.isRequired,
  post2020TaxYear: PropTypes.bool.isRequired,
  taxYear: PropTypes.number.isRequired
}
