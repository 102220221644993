import React, { Component } from 'react';

import urlFor from '../../utils/url_helpers';
import TaxYearOption from './tax_year_option';

export default class OptionsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formAction: "/declarations/new"
    };
  }

  componentDidMount() {
    this.setState({
      formAction: urlFor(this.state.formAction)
    });
  }

  taxYearOptions(taxYear) {
    return (
      <TaxYearOption
        key={taxYear}
        taxYear={taxYear}
        onTaxYearSelect={this.props.onTaxYearSelect}
        active={this.props.selectedYear === taxYear}
      />
    )
  }

  submitButton() {
    return (
      <button type="submit" className="Button Button--primary Button--full-width">
        {this.props.messages.new_declaration_popup.button}
      </button>
    )
  }

  render() {
    const taxYearOptions = this.props.supportedTaxYears.map((taxYear) => {
      return this.taxYearOptions(taxYear);
    })

    return (
      <form id="year_selection_form" action={this.state.formAction}>
        <div className="TaxYearChoice__options">
          { taxYearOptions }
        </div>
        { !!this.props.arbeitnehmer ?
          <input name="arbeitnehmer" type="text" value={this.props.arbeitnehmer} className="TaxYearChoice__hidden-input" readOnly/>
          : null
        }
        <input name="person_id" type="text" value={this.props.person_id || ''} className="TaxYearChoice__hidden-input" readOnly/>
        <input name="tax_year" type="text" value={this.props.selectedYear || ''} className="TaxYearChoice__hidden-input" readOnly/>
        <input name="tax_app" type="text" value={this.props.taxApp} className="TaxYearChoice__hidden-input" readOnly/>
        <div className={`TaxYearChoice__submit-button ${this.props.selectedYear ? '' : 'TaxYearChoice__submit-button--hidden'}`} >
          {this.submitButton()}
        </div>
      </form>
    );
  }
}
