import React, { Component } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import takeRight from "lodash/takeRight";
import Declaration from '../person/components/declaration';
import NewDeclaration from '../person/components/new_declaration';
import NewPropertyTax from "../person/components/new_property_tax";
import ShowNewDeclarationYears from '../person/components/show_new_declaration_years';
import PropertyTax from '../person/components/propertyTax';

class Person extends Component {
  constructor(props) {
    super(props);

    this.toggleNewDeclarations = this.toggleNewDeclarations.bind(this);
    this.showWarning = this.showWarning.bind(this);
    this.state = { showNewDeclarations: false }
  }

  toggleNewDeclarations(e) {
    e.preventDefault();
    this.setState({ showNewDeclarations: !this.state.showNewDeclarations })
  }

  showWarning(year) {
    if(this.currentYearSameAsYear(year) && !this.props.enqueueDeclaration){
      return true
    }

    // for students - if last 3 years - show warning popup
    // for other tools - show warning popup only for the last year
    const taxYears = this.props.supportedTaxYears;
    const taxYearsLength = taxYears.length;
    const taxYearsNumberToShowWarning = taxYearsLength - 4;
    const yearsToShowWarning = takeRight(taxYears, taxYearsNumberToShowWarning);

    return yearsToShowWarning.includes(year);
  }

  warningLocale(year) {
    const { unsubmittable_tax_year_warning, tax_year_warning } = this.props.locales
    if(this.currentYearSameAsYear(year)) {
      let warningMessage = unsubmittable_tax_year_warning
      if(typeof warningMessage.warning === 'string') {
        warningMessage.warning = warningMessage.warning.replace(/%{tax_year}/g, year)
        warningMessage.warning = warningMessage.warning.replace(/%{next_year}/g, year + 1)
      }
      return warningMessage
    }

    return tax_year_warning
  }

  currentYearSameAsYear(year){
    return year === new Date().getUTCFullYear()
  }

  generateHeaderText(isNotEmpty, isOnlyPerson, person, locales) {
    const noPersonId = person && !person.id;
    const isOnlyPersonWithoutId = isOnlyPerson && noPersonId;
    const shouldShowName = isNotEmpty && !isOnlyPersonWithoutId;
    const personNameOrLocales = person && person.first_name ? `${person.first_name} ${person.last_name}` : locales.no_user;
    const text = shouldShowName ? personNameOrLocales : locales.create_declaration_for_which_year;

    return text;
  }

  renderDeclarations() {
    const {person, locales, taxApp, supportedTaxYears, singleTaxApp } = this.props;
    const doToggleNewDeclarations = this.props.isNotGroupedPerson && this.props.isNotEmpty;
    const rowClassName = this.state.showNewDeclarations || !doToggleNewDeclarations ? 'showNewDeclarations' : 'hideNewDeclarations';
    const existingYears = person && person.declarations && [...new Set(person.declarations.map(declaration => declaration.tax_year))];
    const yearsWithoutDeclarations = difference(supportedTaxYears, existingYears);

    return <>
      <h3 className="Heading Heading--3 DeclarationsPerson__Person" title="Property taxes">
        {locales.income_taxes}
      </h3>
      <div className={`DeclarationsPerson__centeredRow ${rowClassName}`}>
        {doToggleNewDeclarations && <ShowNewDeclarationYears locales={locales} toggleNewDeclarations={this.toggleNewDeclarations} />}
        {yearsWithoutDeclarations && yearsWithoutDeclarations.map(year => {
          return <NewDeclaration key={year} year={year} locales={this.warningLocale(year)} showWarning={this.showWarning(year)} taxApp={taxApp} personId={person && person.id} singleTaxApp={singleTaxApp} /> }
        ) }

        {person && person.declarations && person.declarations.map( declaration =>
          <Declaration authenticityToken={this.props.authenticityToken} declaration={declaration} key={declaration.slug} locales={locales} /> )}
      </div>
    </>
  }

  renderPropertyTaxes() {
    const { person, locales, taxApp, singleTaxApp } = this.props;

    return <>
      <h3 className="Heading Heading--3 DeclarationsPerson__Person" title="Property taxes">
        {locales.property_taxes}
      </h3>
      <div className={`Grund__centeredRow`}>
        {person && person.property_taxes && person.property_taxes.map( propertyTax =>
          <PropertyTax authenticityToken={this.props.authenticityToken} propertyTax={propertyTax} key={propertyTax.slug} locales={locales} /> )}
          <NewPropertyTax key="2022" year="Grundsteur" locales={locales} showWarning={this.showWarning("2022")} taxApp={taxApp} personId={person && person.id} singleTaxApp={singleTaxApp} />
      </div>
    </>
  }

  render() {
    const {person, locales, property_tax_first } = this.props;

    return (
      <section>
        <h2 className="Heading Heading--2 DeclarationsPerson__Person" title={person ? `${person.first_name} ${person.last_name}` : ''}>
          {this.generateHeaderText(this.props.isNotEmpty, this.props.isOnlyPerson, person, locales)}
          {person && person.verified && <span className="DeclarationsPerson__verifiedBadge" title={locales.verified_description}>{locales.verified_title}</span>}
        </h2>
        {
          property_tax_first ? <>
            {this.renderPropertyTaxes()}
            {this.renderDeclarations()}
          </> : <>
            {this.renderDeclarations()}
            {this.renderPropertyTaxes()}
          </>
        }
      </section>
    );
  }
}

Person.propTypes = {
  authenticityToken: PropTypes.string,
  isNotEmpty: PropTypes.bool,
  isNotGroupedPerson: PropTypes.bool,
  isOnlyPerson: PropTypes.bool,
  locales: PropTypes.object,
  person: PropTypes.object,
  supportedTaxYears: PropTypes.array,
  taxApp: PropTypes.string,
  enqueueDeclaration: PropTypes.bool
};

export default Person;
