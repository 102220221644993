import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import SumsubWebSdk from '@sumsub/websdk-react'

import FilesContainer from './files_container';
import OnlineSubmit from './online_submit';
import {loadUpdatedContent} from "../../shared/components/load_updated_content";

export default class OnlineSubmissionBox extends Component {
  constructor(props) {
    super(props);

    this.oldFiles = this.props.lastFiles.map((filename) => {
      return { name: filename };
    })

    this.state = {
      currentFiles: this.oldFiles || []
    };

    this.addFile = this.addFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.filesCount = this.filesCount.bind(this);
    this.textContent = this.textContent.bind(this);
    this.filesContainer = this.filesContainer.bind(this);
    this.filePicker = this.filePicker.bind(this);
    this.submitButton = this.submitButton.bind(this);
    this.afterRejection = this.afterRejection.bind(this);
  }

  addFile(data) {
    // When first file added after rejection, old ones are removed
    // from current files
    if (this.afterRejection()) { this.setState({ currentFiles: [] }); }

    // When you want to add some metadata, you can do it here
    const addedFile = {
      name: data.filesUploaded[0].filename,
      path: data.filesUploaded[0].key,
      originalFile: data.filesUploaded[0].originalFile
    }

    this.setState({
      currentFiles: this.state.currentFiles.concat(addedFile)
    });
  }

  removeFile(index) {
    let files = this.state.currentFiles;
    const filepath = files[index].path;

    const data = new FormData();
    data.append('path', filepath);

    const config = {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': this.props.csrfToken
      }
    }

    files.splice(index, 1);

    this.setState({
      currentFiles: files
    });

  }

  filesCount() {
    return this.state.currentFiles.length;
  }

  afterRejection() {
    const current = this.state.currentFiles;
    const old = this.oldFiles;

    return current === old && old.length > 0;
  }

  textContent() {
    const { messages, onChange } = this.props;
    const isNotPropertyTax = this.props.type !== 'property_tax'

    return (
      <div>
        <div className="Text Text--copy Text--blue Text--with-margin"
             dangerouslySetInnerHTML={{__html: this.props.type === 'property_tax' ? messages.pt_description : messages.description}}>
        </div>
        {
          isNotPropertyTax ? (<div className="Link Link--no-underline Text--with-margin" onClick={onChange}>
          {messages.alternative}</div>) : null
        }
        <div className="Text Text--copy Text--with-margin">
          <strong>{messages.requirements}</strong>
        </div>
      </div>
    )
  }

  filesContainer() {
    return (
      <FilesContainer
        currentFiles={this.state.currentFiles}
        removeFile={this.removeFile}
        afterRejection={this.afterRejection()}
        errorMessage={this.props.messages.rejected}
      />
    )
  }


  messageHandler(message)  {
    if(message == "idCheck.onApplicantSubmitted"){
      loadUpdatedContent(null);
    }
  }


  filePicker() {
    const { sumsub_token, pickerLocale } = this.props;

    return (
    <SumsubWebSdk
      accessToken={sumsub_token}
      expirationHandler= { () => {axios.get} }
      config={ {lang: pickerLocale} }
      onMessage={this.messageHandler}
    />
    )
  }

  submitButton() {
    const {
      messages,
      identifyUrl,
      csrfToken,
      uuid,
      person_uuid,
      popupMessages,
      daysSincePurchase,
      userAgent,
      ipAddress,
      taxYear
    } = this.props;

    return (
      <OnlineSubmit
        csrfToken={csrfToken}
        buttonLabel={messages.cta}
        popupMessages={popupMessages}
        submitUrl={identifyUrl}
        files={this.state.currentFiles}
        uuid={uuid}
        person_uuid={person_uuid}
        afterRejection={this.afterRejection()}
        daysSincePurchase={daysSincePurchase}
        userAgent={userAgent}
        ipAddress={ipAddress}
        buttonStyleOutlined={true}
        taxYear={taxYear}
      />
    )
  }

  render() {
    return (
      <div>
        {
          this.textContent()
        }
        {
          this.filesContainer()
        }
        {
          this.filePicker()
        }
        {
          this.submitButton()
        }
      </div>
    );
  }
}

OnlineSubmissionBox.propTypes = {
  apiKey: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  popupMessages: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  s3Bucket: PropTypes.string.isRequired,
  s3Region: PropTypes.string.isRequired,
  identifyUrl: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  person_uuid: PropTypes.string.isRequired,
  lastFiles: PropTypes.array,
  pickerLocale: PropTypes.string.isRequired,
  filestackSecurity: PropTypes.object.isRequired
}
