import React, { Component } from 'react';

export default class PasswordErrors extends Component {
  liCssClass(value, regex_s) {
    const regex = new RegExp(regex_s);
    let cssClass = "PasswordErrors__message";

    if (!regex.test(value)) { cssClass += " PasswordErrors__message--error" }

    return cssClass;
  }

  spanCssClass(value, regex_s) {
    const regex = new RegExp(regex_s);
    let cssClass = null;

    if (regex.test(value)) { cssClass = 'Icons--checkmark' }
    else { cssClass = 'Icons--close' }

    return cssClass + ' PasswordErrors__icon'
  }

  render() {
    const { value, translations, validations } = this.props;

    return (
      <div className="PasswordErrors">
        <div className="PasswordErrors__description">{ translations.description }</div>
        <ul className="PasswordErrors__list">
          { validations.map(([key, regex]) =>
            <li key={key} className={ this.liCssClass(value, regex) }>
              <span className={ this.spanCssClass(value, regex) }></span>
              {translations.messages[key]}
            </li>
          )}
        </ul>
      </div>
    );
  }
}
