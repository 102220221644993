import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WarningDeclarationPopUp extends Component {
  handleClick(e) {
    this.props.formSubmit();
    e.preventDefault();
  }

  render() {
    const {form, locales} = this.props;

    return (
      <div id={`popUp-${form}`} className="js-popup Popup Popup--overlay">
        <div className="Popup__content">
          <header className="Popup__title Heading Heading--3a">{locales.title}</header>
          <span className="Text Text--copy">{locales.warning}</span>
          <form onSubmit={this.handleClick.bind(this)} >
            <div className="Popup__buttons">
              <button type="submit" className="Button--cancel js-popup-close">
              {locales.close}
              </button>
              <button type="submit" className="Button--submit">
                {locales.confirm_button}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

WarningDeclarationPopUp.propTypes = {
  form: PropTypes.string,
  formSubmit: PropTypes.func,
  locales: PropTypes.object
};

export default WarningDeclarationPopUp;
