import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import urlFor from '../../utils/url_helpers';
import Title from '../../shared/components/title';

export default class UserOriginScreen extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      formAction: "/nps_votings/create"
    }
  }

  componentDidMount() {
    this.setState({
      formAction: urlFor(this.state.formAction)
    });
  }

  // Creates the nps voting in the background and changes the component screen
  handleSubmit(origin) {
    const { csrfToken, postUrl, handleUserOriginChange } = this.props;

    const data = new FormData();
    data.append('user_origin', origin);
    data.append('nps_source', 'nps_tool');

    const config = {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    }

    axios.post(postUrl, data, config)
      .then((response) => {
        handleUserOriginChange(response.data.uuid);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Inspired by this article - https://www.kirupa.com/html5/shuffling_array_js.htm
  // Tried also with `arr.sort(() => Math.random() - 0.5)`, but the results are
  // less random (see experiment results here - https://cl.ly/1n0e180D410E,
  // 'Simple way' means the above mentioned logic).
  shuffle(array) {
    let input = array;

    for (let index of input.keys()) {
      let randomIndex = Math.floor(Math.random() * (input.length - index));
      let itemAtIndex = input[randomIndex];

      input[randomIndex] = input[index];
      input[index] = itemAtIndex;
    }
    return input;
  }

  originOption = (origin) => {
    return (
      <div
        key={origin}
        onClick={() => this.handleSubmit(origin)}
        className="Button Button--secondary NpsPopup__user-origin-button"
      >
        <div className="Text Text--subtitle">
          {this.props.messages[origin]}
        </div>
      </div>
    )
  }

  boxTitle = () => {
    const { messages, submissionType, personVerified } = this.props;
    const { title } = messages.title;

    return personVerified ? messages.title.verified_person : messages.title[submissionType]
  }

  render() {
    const { messages, availableOrigins } = this.props;

    return (
      <div id='user_origin_screen'>
        <Title title={this.boxTitle()} />
        <div className="NpsPopup__feedback-question">
          <div className="Text Text--section">
            {messages.question}
          </div>
        </div>
        <form action={this.state.formAction}>
          <div className="NpsPopup__user-origin-options">
            {this.shuffle(availableOrigins).map(this.originOption)}
          </div>
        </form>
      </div>
    );
  }
}

UserOriginScreen.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired,
  handleUserOriginChange: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  submissionType: PropTypes.string.isRequired,
  availableOrigins: PropTypes.array.isRequired,
  personVerified: PropTypes.bool
}
