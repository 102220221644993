import React, { Component } from 'react';

import ClosePopup from '../shared/components/close_popup';

import Title from '../shared/components/title';
import OptionsForm from '../tax_year_choice/components/options_form';

export default class TaxYearChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedYear: null,
      showPopup: props.showPopup
    }
  }

  render() {
    return (
      <div>
        {this.state.showPopup ?
          <div className="TaxYearChoice">
            <div className="Popup Popup--overlay Popup--show">
              <div className="Popup__content Popup__content--wide Popup__content--wider-padding">
                <ClosePopup closePopup={this.props.closePopup} />
                <Title title={this.props.messages.new_declaration_popup.title} />
                <OptionsForm
                  arbeitnehmer={this.props.arbeitnehmer}
                  supportedTaxYears={this.props.supportedTaxYears}
                  messages={this.props.messages}
                  selectedYear={this.state.selectedYear}
                  onTaxYearSelect={selectedYear => this.setState({selectedYear})}
                  personId={this.props.personId}
                  taxApp={this.props.taxApp}
                />
              </div>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}
