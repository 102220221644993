import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ClosePopup from '../../shared/components/close_popup';

export default class ErrorPopup extends Component {
  render() {
    const { messages, closePopup } = this.props;

    return (
      <div className="Popup Popup--overlay Popup--visible">
        <div className="Popup__content Popup__content--wide">
          <ClosePopup closePopup={closePopup} />
          <div className="ElsterWaitingPopup__error-heading">
            <div className="ElsterWaitingPopup__error-image"></div>
            <div className="ElsterWaitingPopup__error-title">
              <span className="Heading Heading--3a">{messages.title}</span>
            </div>
          </div>
          <div className="ElsterWaitingPopup__error-description">
            <span className="Text Text--section">{messages.description_1}</span>
            <br/><br/>
            <span className="Text Text--section">{messages.description_2}</span>
          </div>
        </div>
      </div>
    )
  }
}

ErrorPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  messages: PropTypes.shape({
    title: PropTypes.string,
    description_1: PropTypes.string,
    description_2: PropTypes.string
  })
}
