import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlFor from '../../utils/url_helpers';

export default class NpsOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formAction: "/nps_votings/create"
    }
  }

  componentDidMount() {
    this.setState({
      formAction: urlFor(this.state.formAction)
    });
  }

  render() {
    const scores = Array.from(Array(11).keys());

    const npsOptions = scores.map((score) => {
      return (
        <div
          key={`score_${score}`}
          onClick={() => this.props.onClick(score)}
          className={`Button NpsPopup__score-button NpsPopup__score-button--${score}`}
        >
          <div className="Text Text--subtitle">
            {score}
          </div>
        </div>
      )
    });

    return (
      <form action={this.state.formAction}>
        <div className="NpsPopup__score-options">
          {npsOptions}
        </div>
      </form>
    );
  }
}

NpsOptions.propTypes = {
  onClick: PropTypes.func.isRequired
}
