import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlFor from '../../utils/url_helpers';
import DeleteDeclarationPopUp from './delete_declaration_popup';
import DeclarationCardPartner from './declaration_card_partner';
import DeclarationCardText from './declaration_card_text';
import DeclarationCardDeletable from './declaration_card_deletable';
import DeclarationCardStatus from './declaration_card_status';

class Declaration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkRef: `/declarations/${this.props.declaration.slug}/continue`
    };
  }

  componentDidMount() {
    this.setState({
      linkRef: urlFor(this.state.linkRef)
    });
  }

  render() {
    const { declaration, locales } = this.props;
    
    return (
      <div className={`DeclarationsPerson__fourColumns year-${declaration.tax_year}`} id={declaration.slug}>
        <DeleteDeclarationPopUp
          deleteConfirmationText={locales.delete_declaration_confirmation}
          deleteConfirmText={locales.delete_confirm}
          authenticityToken={this.props.authenticityToken}
          slug={declaration.slug} />
        <a href={this.state.linkRef} className="DeclarationsPerson__taxYear">
          <div className={`DeclarationsPerson__card DeclarationsPerson__card--${declaration.status}`}>
            <DeclarationCardPartner joint_assessment={locales.joint_assessment} partner_person={declaration.partner_person} />
            <DeclarationCardText locales={locales} declaration={declaration} />
            <DeclarationCardDeletable delete_confirm={locales.delete_confirm} declaration={declaration} />
            <DeclarationCardStatus locales={locales} declaration={declaration} />
          </div>
        </a>
      </div>
    );
  }
}

Declaration.propTypes = {
  authenticityToken: PropTypes.string,
  declaration: PropTypes.object,
  locales: PropTypes.object,
};

export default Declaration;
