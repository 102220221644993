import { debounce } from 'tlence';

const immediateDownload = (response) => {
  // Creates a link to the pdf returned by the controller
  const pdfFile = new Blob([response.data], {type: "application/pdf"});
  const filename = response.headers['attachment-filename'];

  // Downloads the file, prevents from moving to a new tab
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // for IE support (MS Edge)
    window.navigator.msSaveOrOpenBlob(pdfFile, filename);
  } else {
    // for non-IE
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(pdfFile);

    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    setTimeout(function(){
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 100)
  }
}

export const downloadFile = debounce(immediateDownload, 500);
