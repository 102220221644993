import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class VoucherInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.coronavirusVatPeriod,
      coupon: '',
      couponValidated: false,
      disable: false,
      message: '',
      success: true,
      errorMessage: null,
    };

    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.updateCoupon = this.updateCoupon.bind(this);
    this.validateCoupon = this.validateCoupon.bind(this);
    this.voucherifyResponseHandler = this.voucherifyResponseHandler.bind(this);
  }

  componentDidMount() {
    Voucherify.initialize(this.props.voucherifyId, this.props.voucherifySecret);
    const queryParams = new URLSearchParams(window.location.search)
    if(queryParams.get("voucher_code")){
      document.getElementsByName("coupon")[0].value = queryParams.get("voucher_code");
        Voucherify.validate({code: queryParams.get("voucher_code"), amount: this.props.amount * 100,
        items: [
          { sku_id: 'declaration', quantity: this.props.quantity }
        ]
      }, this.voucherifyResponseHandler)
    }
  }

  toggleCheckbox() {
    this.setState({
      checked: !this.state.checked,
      coupon: this.state.checked ? this.state.coupon : ''
    })
  }

  updateCoupon(event) {
    this.setState({
      coupon: event.target.value
    })
  }

  validateCoupon(event) {
    event.preventDefault();

    // reset error state
    this.setState({
      message: null
    });

    if (this.state.coupon.length < 1) { return; }
    Voucherify.validate({code: this.state.coupon, amount: this.props.amount * 100,
      items: [
        { sku_id: 'declaration', quantity: this.props.quantity }
      ]
    }, this.voucherifyResponseHandler)
  }

  unitVoucherResponseVoucherifyHandler(response) {
  let amountOfFreeDeclarations = response.discount.unit_type[0];
  const queryParams = new URLSearchParams(window.location.search);
  let selectedQuantity = queryParams.get("quantity") || "1"
  if(selectedQuantity !== amountOfFreeDeclarations){
    const currentUrl = new URL(window.location);
    currentUrl.searchParams.delete("quantity");
    currentUrl.searchParams.append("quantity", amountOfFreeDeclarations);
    currentUrl.searchParams.append("voucher_code", this.state.coupon);
    window.location = currentUrl;
  }else{
    let newGrossPrice = this.props.grossPrice - this.props.grossPrice;
    $('#discounted_price').html(newGrossPrice.toFixed(2) + ' €');

    let newNetPrice = (newGrossPrice / (1 + this.props.taxRate)).toFixed(2);
    let newVatPrice = (newNetPrice * this.props.taxRate).toFixed(2);

    // $('.net_price').html(newNetPrice + ' €');
    // $('.vat_price').html(newVatPrice + ' €');
    const event = new CustomEvent("voucher_price_changed", {
      detail: {
        name: this.state.coupon,
        price: newGrossPrice.toFixed(2)
      }
    });
    this.setState({ message: this.props.messages.success.replace('_', this.props.grossPrice.toFixed(2)), success: true })
    document.dispatchEvent(event)
    this.trackCoupon('checkout_coupon_applied');
  }

  }

  voucherifyResponseHandler(response) {
    if (response.valid) {
      $('.CheckoutPage__payment-item-gross_price').addClass('Text--strikethrough');
      if (response.discount.type === 'UNIT') {
        return this.unitVoucherResponseVoucherifyHandler(response);
      }

      let discount = response.discount.type == 'PERCENT' ? this.props.grossPrice * (response.discount.percent_off / 100) : (response.discount.amount_off / 100);
      let newGrossPrice = this.props.grossPrice - discount;
      newGrossPrice = Math.max(newGrossPrice, 0)
      $('#discounted_price').html(newGrossPrice.toFixed(2) + ' €');

      let newNetPrice = (newGrossPrice / (1 + this.props.taxRate)).toFixed(2);
      let newVatPrice = (newNetPrice * this.props.taxRate).toFixed(2);

      // $('.net_price').html(newNetPrice + ' €');
      // $('.vat_price').html(newVatPrice + ' €');
      const event = new CustomEvent("voucher_price_changed", {
        detail: {
          name: this.state.coupon,
          price: newGrossPrice.toFixed(2)
        }
      });
      this.setState({ message: this.props.messages.success.replace('_', discount.toFixed(2)), success: true })
      document.dispatchEvent(event)
      this.trackCoupon('checkout_coupon_applied');
    } else {
      this.setErrorMessage(response.reason);
      this.trackCoupon('checkout_coupon_denied');
    }
  }

  setErrorMessage(reason) {
    let errorKey;
    switch (reason) {
      case 'voucher expired':
        errorKey = "coupon_expired";
        break;
      case 'quantity exceeded':
        errorKey = "coupon_overlimit";
        break;
      default:
        errorKey = "coupon_invalid";
        this.setState({
          message: this.props.messages.coupon_invalid.replace('_', this.state.coupon),
          success: false
        })
        return
    }

    this.setState({
      message: this.props.messages[errorKey],
      success: false
    });
  }

  trackCoupon(couponEvent) {
    const eventName = couponEvent;
    const eventProperties = {
      'category': 'checkout',
      'coupon': this.state.coupon,
      'user_agent': this.props.userAgent,
      'ip': this.props.ipAddress
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({event: eventName, eventProperties});
    }
    if (typeof mixpanel !== 'undefined') {
      mixpanel.track(eventName, eventProperties);
    }
  }

  render() {
    return (
      <div className="CheckoutPage__bank-account">
        <div className="CheckoutPage__payment-method-entry">
          <span className="Text--summary-title">{this.props.title}</span>
          <div className="Voucher__form">
            <input className="Form__input--text voucher" type="text" onChange={event => this.updateCoupon(event)} name="coupon" />
            <button id="submit_voucher" className={'Button Button--primary Button--md'} onClick={event => this.validateCoupon(event)}>
              <span>{this.props.buttonText}</span>
            </button>
          </div>
          {this.state.message ? <div onClick={() => this.setState({ message: ''} ) }
            // Previously class was Form__message
          className={`StatusBox StatusBox${this.state.success ? '--success' : '--error'}`}>{this.state.message}</div> : null }
        </div>
      </div>
    );
  }
}

VoucherInput.propTypes = {
  voucherifyId: PropTypes.string.isRequired,
  voucherifySecret: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  grossPrice: PropTypes.number.isRequired,
  taxRate: PropTypes.number.isRequired,
  messages: PropTypes.object.isRequired
};
