import React, { Component } from 'react';
import { UTMHandler } from '@wundertax/utm-persist'

export default class UserUTM extends Component {
  render() {
    return (
        <input
          name="user[utm_params]"
          id="user_utm"
          type="hidden"
          value={JSON.stringify(this.value())}
        />
    );
  }

  value() {
    return new UTMHandler().history;
  }
}
