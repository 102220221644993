import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OnlineSubmit from './online_submit';

export default class SkipVerification extends Component {
  constructor(props) {
    super(props);

    this.submitButton = this.submitButton.bind(this);
  }

  submitButton() {
    const { messages,
      submitUrl,
      csrfToken,
      uuid,
      person_uuid,
      popupMessages,
      daysSincePurchase,
      userAgent,
      ipAddress,
      taxYear,
    } = this.props;

    return (
      <OnlineSubmit
        csrfToken={csrfToken}
        messages={messages}
        buttonLabel={messages.cta}
        popupMessages={popupMessages}
        submitUrl={submitUrl}
        files={[]}
        uuid={uuid}
        person_uuid={person_uuid}
        afterRejection={false}
        buttonEnabled={true}
        daysSincePurchase={daysSincePurchase}
        userAgent={userAgent}
        ipAddress={ipAddress}
        taxYear={taxYear}
      />
    )
  }

  legend() {
    return (
      <div className="Text Text--copy Text--wunder-grey Text--with-margin">
        {this.props.messages.description}
      </div>
    )
  }

  render() {
    if(this.props.jointDeclaration && this.props.currentStepNumber == '4' && this.props.firstPersonVerified){

    return (
      <div>
        {
          this.legend()
        }
        {
          this.submitButton()
        }
      </div>
    );
    }else if(!this.props.jointDeclaration){
    return (
      <div>
        {
          this.legend()
        }
        {
          this.submitButton()
        }
      </div>
    );
    }else{
      //this.props.toggleDone(true)

    }
    return null;
  }
}

SkipVerification.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  popupMessages: PropTypes.object.isRequired,
  taxYear: PropTypes.number.isRequired,
  jointDeclaration: PropTypes.bool,
  stepNumber: PropTypes.string,
  currentStepNumber: PropTypes.string,
  done: PropTypes.object,
  toggleDone: PropTypes.func,
  firstPersonVerified: PropTypes.bool
}
