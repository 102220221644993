import ReactOnRails from 'react-on-rails';

import { StatusBox, trackPaymentResult, TryAgain  } from '../adyen/components'
import { Person, AdyenDropinGoogleShopping, AdyenDropin, BuyNowButton, ChooseTaxApp, ConfirmDraftButton, ElsterDownloadButton, NewDeclarationButton, NpsPopup, SubmissionStep, TaxYearChoice, TextPropertyAreaWithCounter, TextAreaWithCounter, Tooltip, TopBarTax, UserPassword, UserUTM, UTMStore, VoucherInput } from '../components';
import { CommentScreen, NpsOptions, ScoreScreen, ThankYouScreen, UserOriginScreen } from '../nps_popup/components';
import { OptionsForm, TaxYearOption } from '../tax_year_choice/components'
import { PasswordErrors } from '../user_password/components';
import { urlFor } from '../utils'
import { DeclarationCardDeletable, DeclarationCardPartner, DeclarationCardStatus, DeclarationCardText, Declaration, DeleteDeclarationPopUp, DeletePropertyTaxPopUp, NewDeclaration, NewPropertyTax, PropertyTax, ShowNewDeclarationYears, TaxRefund, WarningDeclarationPopUp } from '../person/components';
import { ClosePopup, ConfirmationPopup, downloadFile, ErrorPopup, loadUpdatedContent, SubmitPopups, Title, WaitingPopup } from '../shared/components'
import { FilesContainer, OnlineSubmissionBox, OnlineSubmit, PrintedSubmissionBox, PrintedSubmissionNotAvailableBox, SkipVerification, SubmissionAlternatives, SubmissionSwitchButton, VerificationStateBox } from '../submission_step/components';

// This is how react_on_rails can see the Post in the browser.
ReactOnRails.register({
  StatusBox, trackPaymentResult, TryAgain,
  Person, AdyenDropinGoogleShopping, AdyenDropin, BuyNowButton,
  ChooseTaxApp, ConfirmDraftButton, ElsterDownloadButton, NewDeclarationButton, NpsPopup,
  SubmissionStep, TaxYearChoice, TextPropertyAreaWithCounter,
  TextAreaWithCounter, Tooltip, TopBarTax, UserPassword, UserUTM,
  UTMStore, VoucherInput, CommentScreen, NpsOptions, ScoreScreen, ThankYouScreen, UserOriginScreen,
  OptionsForm, TaxYearOption,
  PasswordErrors,
  urlFor,
  DeclarationCardDeletable, DeclarationCardPartner, DeclarationCardStatus, DeclarationCardText, Declaration, DeleteDeclarationPopUp, DeletePropertyTaxPopUp, NewDeclaration, NewPropertyTax, PropertyTax, ShowNewDeclarationYears, TaxRefund, WarningDeclarationPopUp,
  FilesContainer, OnlineSubmissionBox, OnlineSubmit, PrintedSubmissionBox, PrintedSubmissionNotAvailableBox, SkipVerification, SubmissionAlternatives, SubmissionSwitchButton, VerificationStateBox,
  ClosePopup, ConfirmationPopup, downloadFile, ErrorPopup, loadUpdatedContent, SubmitPopups, Title, WaitingPopup
});
