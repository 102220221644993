import React, { Component } from 'react';
import PropTypes from 'prop-types';

import urlFor from '../../utils/url_helpers';
import Title from '../../shared/components/title';

const commentMaxLength = 1000;

export default class CommentScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formAction: "/nps_votings/create"
    }
  }

  componentDidMount() {
    this.setState({
      formAction: urlFor(this.state.formAction)
    });
  }

  render() {
    const { messages, onChange, onSubmit, commentLength } = this.props;

    return (
      <div id="comment_screen">
        <Title title={messages.title} />
        <div className="NpsPopup__comment-label">
          <span className="Text Text--section">{messages.description}</span>
        </div>
        <form action={this.state.formAction} onSubmit={onSubmit}>
          <textarea className="Form__input--text" onChange={onChange} rows="5" maxLength={commentMaxLength} />
          <span className="Form__text-counter Form__mobile" >{ commentLength } / { commentMaxLength }</span>
          <button type="submit" className="Button Button--primary Button--with-margin-top Button--full-width-mobile">
            {messages.button}
          </button>
          <span className="Form__text-counter Form__desktop" >{ commentLength } / { commentMaxLength }</span>
        </form>
      </div>
    );
  }
}

CommentScreen.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  messages: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.string
  })
}
