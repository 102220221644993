import React, { Component } from 'react';
import { UTMHandler } from '@wundertax/utm-persist'

export default class UTMStore extends Component {
  componentDidMount() {
    this.storeUTMData();
  }

  storeUTMData() {
    const url = window.location.href;
    new UTMHandler().processUTM(url);
  }

  render() {
    return null
  }
}
