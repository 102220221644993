import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlFor from '../../utils/url_helpers';
import DeclarationCardText from './declaration_card_text';
import DeclarationCardDeletable from './declaration_card_deletable';
import DeclarationCardStatus from './declaration_card_status';
import DeletePropertyTaxPopUp from './delete_property_tax_popup';

const headingStyles = {
  fontSize: '24px',
  color: '#111111',
  marginBottom: '8px',
  font: '600'
}

class PropertyTax extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkRef: `/property_taxes/${this.props.propertyTax.slug}/continue`
    };
  }

  componentDidMount() {
    this.setState({
      linkRef: urlFor(this.state.linkRef)
    });
  }

  render() {

    const { propertyTax, locales } = this.props;
    const personalAddress = `${propertyTax.pt_street ? propertyTax.pt_street : ''}, ${propertyTax.pt_house_number ? propertyTax.pt_house_number : ''} ${propertyTax.pt_city ? propertyTax.pt_city : ''}`;
    const propertyAddress = `${propertyTax.pt_g_property_street ? propertyTax.pt_g_property_street : ''}, ${propertyTax.pt_property_house_number ? propertyTax.pt_property_house_number : ''} ${propertyTax.pt_property_city ? propertyTax.pt_property_city : ''}`;

    return (
      <div className={`DeclarationsPerson__fourColumns`} id={propertyTax.slug}>
        <DeletePropertyTaxPopUp deleteConfirmationText={locales.delete_property_tax_confirmation}
          deleteConfirmText={locales.delete_confirm}
          authenticityToken={this.props.authenticityToken}
          slug={propertyTax.slug} />
        <a href={this.state.linkRef} className="Grund" style={{ textDecoration: 'none' }}>
          <div className={`DeclarationsPerson__card DeclarationsPerson__card--${propertyTax.status}`}>
            <div className="DeclarationsPerson__cardContent">
              {propertyTax.pt_g_property_street == null ?
                (<h5 className='Text Text--empty-address Text--card-heading'>{locales.pt_no_address}</h5>) :
                (<>
                  <h5 className='Text Text--wunder-black Text--card-heading'>{propertyAddress}</h5>
                  <div>
                    <h5 className="Text Text--wunder-grey">{propertyTax.pt_house_number_addition}</h5>
                    <h4 className="Text Text--wunder-grey">
                      {locales.owner}: {propertyTax.pt_first_name} {propertyTax.pt_last_name}
                    </h4>
                    <h4 className="Text Text--wunder-grey">
                      {locales.region}: {propertyTax.pt_state}
                    </h4>
                  </div>
                </>)}
            </div>

            <DeclarationCardDeletable delete_confirm={locales.delete_confirm} declaration={propertyTax} />
            <DeclarationCardStatus locales={locales} declaration={propertyTax} />
          </div>
        </a>
      </div>
    );
  }
}

PropertyTax.propTypes = {
  authenticityToken: PropTypes.string,
  propertyTax: PropTypes.object,
  locales: PropTypes.object,
};

export default PropertyTax;
