import React, { Component } from 'react';
import PropTypes from 'prop-types';

import WaitingPopup from './waiting_popup';
import ErrorPopup from './error_popup';

export default class SubmitPopups extends Component {
  constructor(props) {
    super(props);

    this.renderSwitch = this.renderSwitch.bind(this);
  }

  renderSwitch() {
    const { popupMessages, popupVersion, closePopup } = this.props;

    switch(popupVersion) {
      case 1:
        return (
          <WaitingPopup
            message={popupMessages.waiting}
            closePopup={closePopup}
          />
        );
      case 2:
        return (
          <ErrorPopup
            messages={popupMessages.error}
            closePopup={closePopup}
          />
        );
      default:
        return null;
    };
  }

  render() {
    return (
      <div>
        {this.props.showPopups ?
          this.renderSwitch()
          : null
        }
      </div>
    );
  }
}

SubmitPopups.propTypes = {
  showPopups: PropTypes.bool.isRequired,
  popupMessages: PropTypes.object.isRequired,
  popupVersion: PropTypes.number.isRequired,
  closePopup: PropTypes.func.isRequired
}
