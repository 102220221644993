import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TextAreaWithCounter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: this.props.value || ''
    }

    this.maxLength = this.props.inputOptions.max || 999;

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({input: event.target.value});
  }

  fieldName() {
    return `declaration[${this.props.name}]`;
  }

  fieldId() {
    return `declaration_${this.props.name}`;
  }

  render() {
    const { inputOptions } = this.props;

    return (
      <div>
        <textarea
          name={this.fieldName()}
          id={this.fieldId()}
          className={inputOptions.class}
          onChange={this.onChange}
          rows={inputOptions.rows}
          maxLength={this.maxLength}
          value={this.state.input}
        />
        <span className="Form__text-counter" >{ this.state.input.length } / { this.maxLength }</span>
      </div>
    );
  }
}

TextAreaWithCounter.propTypes = {
  name: PropTypes.string.isRequired,
  inputOptions: PropTypes.object.isRequired,
  value: PropTypes.string
}
