import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SubmissionSwitchButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { messages, onlineSubmission } = this.props;

    return (
      <div className="SubmissionAlternatives__toggle-container" onClick={this.props.onChange}>
        <div className={`SubmissionAlternatives__toggle-option ${onlineSubmission ? 'SubmissionAlternatives__toggle-option--active' : ''}`}>
          <span className="Text Text--toggle-button">{messages.online}</span>
        </div>
        {this.props.type != 'property_tax' ?  <div className={`SubmissionAlternatives__toggle-option ${onlineSubmission ? '' : 'SubmissionAlternatives__toggle-option--active'}`}>
          <span className="Text Text--toggle-button">{messages.printed}</span>
        </div> : null }
      </div>
    );
  }
}

SubmissionSwitchButton.propTypes = {
  onlineSubmission: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}
