import React, { Component } from 'react';

export default class ChooseTaxApp extends Component {
  warningLabel() {
    if (this.props.displayWarning) {
      return (
        <div className={`DeclarationsPerson__cardFooter DeclarationsPerson__fill_out_data ChooseTaxAppCard`}>
          <i name="statusIcon" className={`Icons--warn-circle`} />
          <span>{this.props.warningText}</span>
        </div>
      );
    }
  }

  render() {
    return (
      <form
        action="/app/declarations/new" ref={form => this.form = form}>
        <input name="tax_year" type="hidden" value={this.props.year} readOnly />
        <input name="tax_app" type="hidden" value={this.props.taxApp} readOnly />
        <input name="person_id" type="hidden" value={this.props.personId} readOnly />
        <div
          className={`DeclarationsPerson__fourColumns ChooseTaxAppFourColumns js-popup-open`}>
          <button type="submit" className="DeclarationsPerson__newYear ChooseTaxAppButton--shadow">
            <div className={`ChooseTaxAppButton ${!this.props.displayWarning && "ChooseTaxAppButton--rounded"}`}>{this.props.taxAppHeadline}
            </div>
            {this.warningLabel()}
          </button>
        </div>
      </form >
    );
  }
}
